import axios from "axios";

// remoto "https://marine-outpost-327402.uc.r.appspot.com/"
// local http://localhost:3001/
// const base_url = "http://localhost:3001/users";

export const authAxios = axios.create();

authAxios.interceptors.request.use(function (config) {
    const token = sessionStorage.getItem("token");
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
})

axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log(error);
    console.log("Data response");

    console.log(error.response.status);
    if (error.response.status === 401) { 
        console.log("Sai do pé chulé");
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('name');
        sessionStorage.removeItem('email');
        sessionStorage.removeItem('admin');
        sessionStorage.removeItem('createdAt');
        // window.location.reload();
    }
    return Promise.reject(error);
});

import React, { Component } from 'react';

import "./trap-nav-tabs.scss";

import TabPhotosList from "./../tab-photos-list/tab-photos-list";
import TabReport from "./../tab-report/tab-report";
import TabIdentification from '../tab-identification/tab-identification';

export default class TrapNavTabs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //**** Start - Inputs ****//
            inputImagesIDs: this.props.imagesIDs,
            inputTrapID: this.props.trapID,
            inputPhones: this.props.phones,
            inputReport: this.props.report,
            //**** End - Inputs ****//

            //**** Start - Variables ****//
            activeTab: "image"
            //**** End - Variables ****//
        };
    }

    async componentDidMount() { }

    setActiveTab(id) {
        this.setState({ activeTab: id });
    }

    render() {

        return (
            <div className='mt-3 mx-2 mb-3'>
                <div className='row py-3 shadow rounded'>
                    <div className='col-12'>
                        <div className='d-flex justify-content-center align-items-end'>
                            <button
                                className={this.state.activeTab === "image" ? "btn btn-sm btn-primary mx-1 shadow-sm" : "btn btn-sm btn-outline-primary mx-1 shadow-sm"}
                                onClick={e => this.setActiveTab('image')}>
                                Photos
                            </button>
                            <button
                                className={sessionStorage.getItem('role') !== "admin" ? "hidden" : this.state.activeTab === "report" ? "btn btn-sm btn-primary mx-1 shadow-sm" : "btn btn-sm btn-outline-primary mx-1 shadow-sm"}
                                onClick={e => this.setActiveTab('report')}>
                                Reports
                            </button>
                            <button
                                className={this.state.activeTab === "identification" ? "btn btn-sm btn-primary mx-1 shadow-sm" : "btn btn-sm btn-outline-primary mx-1 shadow-sm"}
                                onClick={e => this.setActiveTab('identification')}>
                                Identifications
                            </button>
                        </div>
                        <div className="outlet border-top mt-3">
                            {this.state.activeTab === "image" ? <TabPhotosList photosIDs={this.state.inputImagesIDs}></TabPhotosList> : null}
                            {this.state.activeTab === "report" ? <TabReport trapID={this.state.inputTrapID} phones={this.state.inputPhones}></TabReport> : null}
                            {this.state.activeTab === "identification" ? <TabIdentification report={this.state.inputReport}></TabIdentification> : null}

                        </div>
                    </div>
                </div>
            </div>
        );
    }

} 
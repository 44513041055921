import React, { Component } from 'react';
import moment from 'moment';
import { getInfo, getImage, create, update, generateReport, sendReport, del } from '../services/traps.service';
import { PaginationItem, PaginationLink } from 'reactstrap';
import "./traps-manage.scss";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import L from 'leaflet';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import savingLoading from "./../../../../assets/saving-loading.gif";
import * as ELG from 'esri-leaflet-geocoder';
import { getList } from '../../field/services/field.service';

class TrapsManage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // variaveis de controle
            id: props.match.params.id,
            name: "",
            description: "",
            lat: "",
            lng: "",
            images: [],
            b64: [],
            scheduledPhotos: [],
            createdAt: null,
            loadingReq: false,
            loading: true,
            successReq: null,
            errorReq: null,
            error: null,
            invalidName: false,
            invalidDescription: false,
            deleteToggle: false,
            pagesCount: 1,
            pageSize: 12,
            currentPage: 0,
            // clicked: false
            orderBy: 'lastCreated',
            modal: false,
            aux: null,
            map: {
                lat: -13,
                lng: -52,
                zoom: 6,
                hasLocation: false,
                markerPos: { lat: "", lng: "" },
            },
            //field
            avaibleFields: [],
            fieldId: "",
            reportAmountFlies: 0,
            reportDate: moment().format("yyyy-MM-DD"),
            togglePdf: false,
            generatingReport: false,
        };

        this.myIcon = new L.Icon({
            iconUrl: require('../../../../assets/marker.png'),
            iconSize: new L.Point(45, 45),
            iconAnchor: [22, 44],
        });

        this.openModal = this.openModal.bind(this);
    }

    //modal
    openModal(element) {
        // console.log(element) //recebe o id da foto clicada
        this.setState({ aux: element });

        this.setState({
            modal: !this.state.modal,
        });
    }


    //chamar imagem no servidor e armazenar no this.state.images[id]
    async getB64(id) {
        var _return;
        await getImage(id).then(res => {
            _return = res.data.data;
        }).catch(err => {
            console.log("Erro ao carregar b64");
        });
        return _return;
    }

    //mapa
    setMapZoom = (e) => {
        if (this.mapRef) {
            var _map = { ...this.state.map };
            _map.zoom = this.mapRef.viewport.zoom;
            this.setState({ map: _map });
        }
    }

    onMapReady = () => {
        //Search box on map 
        const map = this.mapRef.leafletElement;
        new ELG.Geosearch().addTo(map);
        new L.LayerGroup().addTo(map);
    }

    handleClickMap = (e) => {
        var _map = { ...this.state.map };
        _map.markerPos = e.latlng;
        _map.hasLocation = true;
        _map.lat = _map.markerPos.lat.toFixed(4);
        _map.lng = _map.markerPos.lng.toFixed(4);
        _map.markerPos.lat = _map.markerPos.lat.toFixed(4);
        _map.markerPos.lng = _map.markerPos.lng.toFixed(4);
        this.setState({ map: _map });
        this.setState({ invalidLng: this.state.map.markerPos.lng === "" ? true : false });
        this.setState({ invalidLat: this.state.map.markerPos.lat === "" ? true : false });
    }

    //paginacao
    getPaginationRange = () => {
        var list = [];
        var _return = [];
        for (var j = this.state.currentPage - 5; j <= this.state.currentPage + 5; j++) {
            if (j > 0 && j < Array(this.state.pagesCount).length - 1) {
                list.push(j);
            }
        }

        list.forEach((element) => {
            _return.push(
                <PaginationItem active={element === this.state.currentPage} key={element}>
                    <PaginationLink onClick={e => this.handleClick(e, element)} href="#">
                        {element + 1}
                    </PaginationLink>
                </PaginationItem>
            );
        })

        return _return;
    }

    componentDidMount() {
        if (this.state.id === "new") {
            this.setState({ loading: false });
        } else {
            getInfo(this.state.id).then(res => {
                this.setState({ ...res.data.data });
                this.setState({ loading: false });
                this.setState({ pagesCount: Math.ceil(this.state.images.length / this.state.pageSize) });
                // data:image/jpeg;base64,${this.getB64(id)}
                // console.log(` ola ${this.state.images}`);

                //mapa
                if (this.state.map.markerPos.lat !== null) {
                    var _map = { ...this.state.map };
                    _map.lat = _map.markerPos.lat.toFixed(4);
                    _map.lng = _map.markerPos.lng.toFixed(4);
                    _map.zoom = 8;
                    this.setState({ map: _map });
                }
                this.state.images.forEach(async (index, element) => {
                    var aux = this.state.b64;
                    aux[element] = "loading";
                    this.setState({ b64: aux });
                });

                this.state.images.forEach(async (index, element) => {
                    var newImage = await this.getB64(index);
                    var aux = this.state.b64;

                    aux[this.state.images.length - 1 - element] = newImage;
                    //aux[element] = newImage;
                    this.setState({ b64: aux });
                    // console.log(`${this.state.b64}`);
                });
            }).catch(err => {
                this.setState({ loading: false });
                this.setState({ error: err.message });
            });
        }
        this.getFields()

    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        this.setState({ invalidName: this.name === "" ? true : false });
        this.setState({ invalidDescription: this.description === "" ? true : false });
    }

    handleChangeLatLng = (event) => {
        var _map = { ...this.state.map };
        _map.markerPos[event.target.name] = event.target.value;
        _map.hasLocation = true;
        this.setState({ map: _map });
        this.setState({ invalidLng: this.state.map.markerPos.lng === "" ? true : false });
        this.setState({ invalidLat: this.state.map.markerPos.lat === "" ? true : false });
    }

    handleClick(e, index) {

        e.preventDefault();

        this.setState({
            currentPage: index
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.shapeFile !== prevState.shapeFile) {
            var allow = Object.keys(this.state.shapeFile).length === 0;

            this.setState({
                allowDraw: allow,
            })
        }
    }

    delete = () => {
        this.setState({ loadingReq: true });
        this.setState({ errorReq: null });

        del(this.state.id).then(res => {
            this.setState({ successReq: "Deleted successfully" });
            setTimeout(() => {
                this.props.history.push('/traps');
            }, 1500);
        }).catch(err => {
            this.setState({ loadingReq: false });
            this.setState({ errorReq: err.message });
        });
    }

    toggleDelete = () => this.setState({ deleteToggle: !this.state.deleteToggle });

    //salvar
    save = () => {
        this.setState({ loadingReq: true });
        this.setState({ successReq: null });
        this.setState({ errorReq: null });

        let _body = {
            name: this.state.name,
            description: this.state.description,
            lat: this.state.lat,
            lng: this.state.lng,
            map: this.state.map,
            fieldId: this.state.fieldId === "" ? null : this.state.fieldId,
        };

        if (this.state.id === "new") {
            create(_body).then(res => {
                this.setState({ successReq: "Saved successfully" });
                setTimeout(() => {
                    this.props.history.push('/traps');
                }, 1500);
            }).catch(err => {
                this.setState({ loadingReq: false });
                this.setState({ invalidName: this.state.name === "" ? true : false });
                this.setState({ invalidDescription: this.state.description === "" ? true : false });
                this.setState({ invalidLng: this.state.map.markerPos.lng === "" ? true : false });
                this.setState({ invalidLat: this.state.map.markerPos.lat === "" ? true : false });
                this.setState({ errorReq: err.message === "Request failed with status code 500" ? "Please, check the entered information and try again" : err.message });
            });
        } else {
            update(this.state.id, _body).then(res => {
                this.setState({ successReq: "Saved successfully" });
                setTimeout(() => {
                    this.props.history.push('/traps/' + this.state.id);
                }, 1500);
            }).catch(err => {
                this.setState({ loadingReq: false });
                this.setState({ invalidName: this.state.name === "" ? true : false });
                this.setState({ invalidDescription: this.state.description === "" ? true : false });
                this.setState({ invalidLng: this.state.map.markerPos.lng === "" ? true : false });
                this.setState({ invalidLat: this.state.map.markerPos.lat === "" ? true : false });
                this.setState({ errorReq: err.message === "Request failed with status code 500" ? "Please, check the entered information and try again" : err.message });
            });
        }
    }

    generatePDF = () => {
        this.setState({ generatingReport: true });
        let postBody = {
            "target": this.state.reportAmountFlies,
            "trap": this.state.name,
            "date": "No dia " + moment(this.state.reportDate).format("DD/MM/YYYY"),
        };

        generateReport(postBody).then(res => {
            window.open(URL.createObjectURL(res.data));
            console.log(res);

            this.setState({ successReq: "Saved successfully" });
            setTimeout(() => {
                this.setState({ successReq: "" });
                this.setState({ generatingReport: false });
            }, 2000);
        }).catch(err => {
            this.setState({ errorReq: err.message === "Request failed with status code 500" ? "Please, check the entered information and try again" : err.message });
            setTimeout(() => {
                this.setState({ errorReq: "" });
                this.setState({ generatingReport: false });
            }, 2000);
        });
    }


    sendPDF = () => {
        this.setState({ generatingReport: true });
        let postBody = {
            "target": this.state.reportAmountFlies,
            "trap": this.state.name,
            "date": "No dia " + moment(this.state.reportDate).format("DD/MM/YYYY"),
        };

        generateReport(postBody).then(async (res) => {
            let b64PDF = await this.getBase64(res.data);

            let sendReportBody = {
                "to": this.state.phones,
                "description": "TRD",
                "message": "Mensagem de teste 123",
                "pdf": b64PDF,
                "type": "whatsapp",
            };
            sendReport(sendReportBody).then(resSend => {
                // window.open(URL.createObjectURL(res.data));
                // console.log(res);

                this.setState({ successReq: "Sent successfully" });
                setTimeout(() => {
                    this.setState({ successReq: "" });
                    this.setState({ generatingReport: false });
                }, 2000);
            }).catch(err => {
                this.setState({ errorReq: err.message === "Request failed with status code 500" ? "Please, check the entered information and try again" : err.message });
                setTimeout(() => {
                    this.setState({ errorReq: "" });
                    this.setState({ generatingReport: false });
                }, 2000);
            });
        }).catch(err => {
            this.setState({ errorReq: err.message === "Request failed with status code 500" ? "Please, check the entered information and try again" : err.message });
            setTimeout(() => {
                this.setState({ errorReq: "" });
                this.setState({ generatingReport: false });
            }, 2000);
        });
    }

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    //field
    getFields = () => {
        getList().then(res => {
            var arrived = res.data.data
            this.setState({
                avaibleFields: arrived
            })
        }).catch(err => {
            console.log(err)
        })

    }

    buildFields = () => {
        var fieldsArr = this.state.avaibleFields

        var options = fieldsArr.map((field) => {
            return <option value={field._id} key={field._id + field.name}>{field.name}</option>
        })


        return (
            <select className={'form-control'} name="fieldId" value={this.state.fieldId._id} onChange={this.handleChange}>
                <option value={-1}>Select a Field</option>
                {options}
            </select>
        )

    }

    toggle = (input) => {
        this.setState({ [input]: !this.state[input] });
    }

    render() {

        const marker = this.state.map.hasLocation ? (
            <Marker position={[this.state.map.markerPos.lat, this.state.map.markerPos.lng]} icon={this.myIcon}>
                <Popup>You soil are here</Popup>
            </Marker>
        ) : null

        return (
            this.state.loading ? <div className="pre-loader"></div> :
                this.state.error != null ? <div className="h-100 w-100 d-flex justify-content-center align-items-center mt-5">
                    <div>
                        <h4>Ooops, something is wrong!</h4>
                        <div className="alert alert-danger w-100" role="alert">
                            {this.state.error}
                        </div>
                    </div>
                </div> :
                    <div>
                        <div className="row mt-3">
                            {/* <div className="col d-flex justify-content-between align-items-center"> */}
                            <div className='col-sm-6 col'>
                                <h2 className={this.state.id === "new" ? "hidden" : ""}>{this.state.name} Information</h2>
                                <h2 className={this.state.id === "new" ? "" : "hidden"}>Create Trap</h2>
                            </div>
                            <div className={sessionStorage.getItem('role') === "admin" ? 'col-sm-6 col d-flex justify-content-end align-items-center' : 'hidden'}>
                                <Dropdown disabled={this.state.loadingReq} className={this.state.id !== "new" ? '' : 'hidden'} isOpen={this.state.deleteToggle} toggle={() => this.toggleDelete()}>
                                    <DropdownToggle color="danger">
                                        Delete
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {/*Confirmar*/}
                                        <DropdownItem onClick={() => this.delete()}>Confirm</DropdownItem>
                                        <DropdownItem divider />
                                        {/*Cancelar*/}
                                        <DropdownItem onClick={() => this.toggle()}>Cancel</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <button className="btn btn-outline-danger ml-5" disabled={this.state.loadingReq} onClick={() => this.props.history.push('/traps')}>Cancel</button>
                                <button className="btn btn-success ml-2" disabled={this.state.loadingReq} onClick={() => this.save()}>
                                    {/*Carregando para salvar*/}
                                    <div className={this.state.loadingReq ? "hidden" : ""}>
                                        Save
                                    </div>
                                    <div className={this.state.loadingReq ? "" : "hidden"}>
                                        Saving...
                                        <img src={savingLoading} className="ml-2" height="25px" alt="" />
                                    </div>
                                </button>
                            </div>
                            <div className={sessionStorage.getItem('role') !== "admin" ? 'col-sm-6 col d-flex justify-content-end align-items-center' : 'hidden'}>
                                <button className="btn btn-outline-primary ml-5" disabled={this.state.loadingReq} onClick={() => this.props.history.push('/traps')}>Back</button>
                            </div>
                            {/* </div> */}
                        </div>

                        <div className={this.state.errorReq !== null || this.state.successReq !== null ? 'row mt-0 mb-n4' : 'hidden'}>
                            <div className="col d-flex justify-content-end align-items-center">
                                <div className={this.state.errorReq !== null ? 'alert alert-danger' : 'hidden'} style={{ position: 'relative' }} role="alert">
                                    {this.state.errorReq}
                                </div>
                                <div className={this.state.successReq !== null ? 'alert alert-success' : 'hidden'} style={{ position: 'relative' }} role="alert">
                                    {this.state.successReq}
                                </div>
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-6 mt-1 mb-2">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Name</div>
                                            </div>
                                            <input className={this.state.invalidName ? "form-control is-invalid" : "form-control"} name="name" type="text" value={this.state.name} onChange={this.handleChange}></input>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Description</div>
                                            </div>
                                            <input className={this.state.invalidDescription ? "form-control is-invalid" : "form-control"} type="text" name="description" value={this.state.description} onChange={this.handleChange}></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="row">
                                    <div className="col-md-6 mt-1">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                {/*Latitude*/}
                                                <div className="input-group-text">Latitude</div>
                                            </div>
                                            {/*Salva os dados da Latitude no input*/}
                                            <input type="number" className="form-control" name="lat" value={this.state.map.markerPos.lat} onChange={this.handleChangeLatLng} />
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-1">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                {/*Latitude*/}
                                                <div className="input-group-text">Longitude</div>
                                            </div>
                                            {/*Salva os dados da Longitude no input*/}
                                            <input type="number" className="form-control" name="lng" value={this.state.map.markerPos.lng} onChange={this.handleChangeLatLng} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">Trap Field</div>
                                            </div>
                                            {this.buildFields()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-2'>
                            <div className='col'>
                                {/* MAPA? */}
                                <Map
                                    center={[this.state.map.lat, this.state.map.lng]}
                                    length={4}
                                    onClick={this.handleClickMap}
                                    zoom={this.state.map.zoom}
                                    style={{ height: "430px", borderRadius: "10px" }}
                                    ref={(ref) => { this.mapRef = ref; }}
                                    onZoomEnd={this.setMapZoom}
                                    whenReady={this.onMapReady}>
                                    <TileLayer
                                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicmFmYWVsLWxhdXhlbiIsImEiOiJjanlvbThnbmoxNjM2M2JudHhqc3ZtZXdqIn0.v3sa933wVSor5tM_zSkLww"
                                    />
                                    {marker}
                                </Map>
                            </div>
                        </div>
                    </div>
        )
    }
}

export default TrapsManage;
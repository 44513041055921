import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Farm from './components/pages/farm/farm-list/farm-list';
import Farminfo from './components/pages/farm/farm-info/farm-info';

import Login from './components/pages/login/login';

import Header from './components/header/header';
// import Menu from './components/left-menu/left-menu';

import 'leaflet/dist/leaflet.css';
import "esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css";

import Field from './components/pages/field/field-list/field-list';
import Fieldinfo from './components/pages/field/field-info/field-info';

import Season from './components/pages/season/season-list/season-list';
import Seasoninfo from './components/pages/season/season-info/season-info';
import SeasonManage from './components/pages/season/season-manage/season-manage';

import Culture from './components/pages/culture/culture-list/culture-list';
import Cultureinfo from './components/pages/culture/culture-info/culture-info';

import Traps from './components/pages/traps/traps-list/traps-list';
import TrapsInfo from './components/pages/traps/traps-info/traps-info';
import TrapsManage from './components/pages/traps/traps-manage/traps-manage';

import "esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css";

//import Climate from './components/pages/climate/climate-list/climate-list';
//import Climateinfo from './components/pages/climate/climate-info/climate-info';
//import ClimateData from './components/pages/climate/climate-data/climate-data';
//import ClimateWeatherdata from './components/pages/climate/climate-weatherdata/climate-weatherdata';

//import Crop from './components/pages/crop/crop-list/crop-list';
//import Cropinfo from './components/pages/crop/crop-info/crop-info';

//import Manual from './components/pages/manual/manual';

ReactDOM.render(
    <BrowserRouter>

      {sessionStorage.getItem('token') !== null ?
        <React.Fragment>
          <Header />
          <div className="container-fluid">
            <div className="row h-100">

              {/* <div className="col-auto pl-0">
                <Menu />
              </div> */}

              <div className="col">
                <Switch>
                  <Route exact path='/' component={App} />
                  <Route exact path='/farm' component={Farm} />
                  <Route exact path='/farm/:id' component={Farminfo} />
                  <Route exact path='/field' component={Field} />
                  <Route exact path='/field/:id' component={Fieldinfo} />
                  <Route exact path='/season' component={Season} />
                  <Route exact path='/season/:id' component={Seasoninfo} />
                  <Route exact path='/season/manage/:id' component={SeasonManage} />
                  <Route exact path='/culture' component={Culture} />
                  <Route exact path='/culture/:id' component={Cultureinfo} />
                  <Route exact path='/traps' component={Traps} />
                  <Route exact path='/traps/:id' component={TrapsInfo} />
                  <Route exact path='/traps/manage/:id' component={TrapsManage} />
                  {/* <Route exact path='/climate' component={Climate} />
                  <Route exact path='/climate/:id' component={Climateinfo} />
                  <Route exact path='/climatedata' component={ClimateData} />
                  <Route exact path='/climateweatherdata' component={ClimateWeatherdata} />
                  <Route exact path='/crop' component={Crop} />
                  <Route exact path='/crop/:id' component={Cropinfo} />
                  <Route exact path='/handbook' component={Manual} />
                 */}
                </Switch>
              </div>

            </div>
          </div>
        </React.Fragment>
        : <Login />}

    </BrowserRouter>,
  document.getElementById('root')
);


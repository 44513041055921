import React, { Component } from 'react';
import "./login.scss";
import { auth } from "./services/login.service";

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: "",
            password: "",

            joing: false,

            loginErrror: false,
        };
    }

    handleChange = (event) => {
        var _name = event.target.name;
        var _value = event.target.value;
        this.setState({ [_name]: _value });
    }

    login = (e) => {
        e.preventDefault();

        this.setState({ joing: true });

        auth({ email: this.state.user, password: this.state.password }).then(res => {

            console.log(res);
            if (res.data.code === "SUCCESS") {
                this.setState({ joing: false });
                this.setState({ loginErrror: false });
                sessionStorage.setItem('name', res.data.data.user.name);
                sessionStorage.setItem('email', res.data.data.user.email);
                sessionStorage.setItem('createdAt', res.data.data.user.createdAt);
                sessionStorage.setItem('role', res.data.data.user.role);
                sessionStorage.setItem('admin', 0);
                sessionStorage.setItem('token', res.data.data.jwt);
                window.location.reload();
            } else {
                this.setState({ joing: false });
                this.setState({ loginErrror: true });
            }
        }).catch(err => {
            this.setState({ loginErrror: true });
            this.setState({ joing: false });
            console.log(err.message);
        });

        // setTimeout(() => {
        //     this.setState({ joing: false });
        //     if (this.state.user === "convidado" && this.state.password === "sejabemvindo") {
        //         this.setState({ loginErrror: false });
        //         sessionStorage.setItem('token', "vocetempermissaoparausaraferramenta!@#@#1234321");
        //         window.location.reload();
        //     } else {
        //         this.setState({ loginErrror: true });
        //     }
        // }, 1000);

    }

    render() {

        return (

            <div className="d-flex justify-content-center align-items-center vh-100 login-main">

                <div className="card card-login mx-2">
                    <div className="card-header">
                        <h5 className="card-title w-100 d-flex justify-content-center mb-0">Tech Trap</h5>
                    </div>
                    <div className="card-body">
                        <form onSubmit={this.login}>
                            <div className="input-group mt-1">
                                <div className="input-group-prepend">
                                    <div className="input-group-text card-color">Username</div>
                                </div>
                                <input type="text" className="form-control" name="user" value={this.state.user} onChange={this.handleChange} disabled={this.state.joing} />
                            </div>

                            <div className="input-group mt-3">
                                <div className="input-group-prepend">
                                    <div className="input-group-text pr-3 card-color">Password</div>
                                </div>
                                <input type="password" className="form-control" name="password" value={this.state.password} onChange={this.handleChange} disabled={this.state.joing} />
                            </div>


                            <button className="btn btn-primary w-100 mt-4 card-color-btn" type="submit" disabled={this.state.joing}>
                                {this.state.joing === true ? "Entrando..." : "Log In"}
                            </button>

                            <div className={this.state.loginErrror ? "alert alert-danger mt-1" : "hidden"}>
                                Invalid Username/Password, please try again!
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        )
    }
}

export default Login;
import { authAxios } from "./../../../../interceptors/auth";

// const base_url = "http://localhost:3001/"; //local
const base_url = "https://marine-outpost-327402.uc.r.appspot.com/"; //deploy 

export function getList() {
    return authAxios.get(base_url + "cultures");
}

export function getInfo(_id) {
    return authAxios.get(base_url + "cultures/" + _id);
}

export function create(_climate) {
    return authAxios.post(base_url + "cultures", _climate);
}

export function update(_id, _climate) {
    return authAxios.post(base_url + "cultures/" + _id, _climate);
}

export function del(_id) {
    return authAxios.delete(base_url + "cultures/" + _id);
}
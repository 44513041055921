import React, { useState } from 'react';
import './header.scss';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import LogoTech from "./../../assets/LogoTech.png";
import { NavLink } from 'react-router-dom'

const Header = (props) => {

    const history = useHistory()

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const logout = () => {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('name');
        sessionStorage.removeItem('email');
        sessionStorage.removeItem('admin');
        sessionStorage.removeItem('createdAt');
        history.push('/');
        window.location.reload();
    }

    return (

        <div className="row shadow-sm d-flex align-items-end">
            <div className='col-sm-12 col-md-2 d-flex justify-content-center align-items-center'>
                <Link className='pl-2 py-2' to="/"><img width='160px' src={LogoTech} alt="" /></Link>
            </div>
            <div className='col-sm-12 col-md-8 d-flex justify-content-center align-items-end'>
                <ul className='nav menu-subtopic'>
                    <li className={sessionStorage.getItem('role') === "admin" ? 'mx-4' : 'hidden'}><NavLink to="/culture">Cultures</NavLink></li>
                    <li className={sessionStorage.getItem('role') === "admin" ? 'mx-4' : 'hidden'}><NavLink to="/farm">Farms</NavLink></li>
                    <li className={sessionStorage.getItem('role') === "admin" ? 'mx-4' : 'hidden'}><NavLink to="/field">Fields</NavLink></li>
                    <li className={sessionStorage.getItem('role') === "admin" ? 'mx-4' : 'hidden'}><NavLink to="/season">Season</NavLink></li>
                    <li className='mx-4'><NavLink to="/traps">Traps</NavLink></li>
                </ul>
            </div>
            <div className='col-sm-12 col-md-2 d-flex justify-content-center align-items-center'>

                <Dropdown className="pr-2 py-3" isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle caret outline color="primary" size="sm">
                        {sessionStorage.getItem('name')}
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem disabled>{sessionStorage.getItem('email')}</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem onClick={() => logout()}>Logout</DropdownItem>
                    </DropdownMenu>
                </Dropdown>

            </div>
            <div className='col'> </div>
        </div>

    )

}

export default Header;
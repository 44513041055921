/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';

import { getImage } from '../../../services/traps.service';
import preLoader from "./../../../../../../assets/pre-loader.gif";
import "./last-image.scss";

import moment from "moment";

export default class LastImage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //**** Start - Inputs ****//
            inputImages: this.props.images,
            //**** End - Inputs ****//

            //**** Start - Variables ****//
            lastImageB64: "",
            lastImageHour: 0,
            //**** End - Variables ****//
        };
    }

    async componentDidMount() {
        this.setState({ lastImageB64: await this.getB64(this.state.inputImages.at(-1)) });
        this.setState({ lastImageHour: this.getDateDiff(this.state.lastImageB64.createdAt) })
    }

    async getB64(id) {
        var _return;
        await getImage(id).then(res => {
            _return = res.data.data;
        }).catch(err => {
            _return = "error";
            console.log("Erro ao carregar b64");
        });
        return _return;
    }

    getDateDiff(date) {
        var hours = moment.duration(moment().diff(moment(date))).asHours();

        if (hours < 5) return { label: hours.toFixed(0), badgeColor: "success" };
        // else if (hours < 11) return { label: hours.toFixed(0), badgeColor: "danger" };
        else return { label: hours.toFixed(0) < 999 ? hours.toFixed(0) : "+999", badgeColor: "danger" };
    }

    render() {
        return (
            <div className='row bg-white'>
                <div className='col-12'>
                    <div className='row '>
                        <div className='col-12 d-flex justify-content-center align-items-center'>
                            {
                                this.state.lastImageB64 === "error" ?
                                    <span>No photos yet...</span> :
                                    <img className='img-fluid rounded' src={this.state.lastImageB64 === "" ? preLoader : `data:image/jpeg;base64,${this.state.lastImageB64.b64}`} />
                            }
                        </div>
                    </div>
                    <div className='row'>
                        <div className={this.state.lastImageB64 === "error" ? 'hidden' : 'col-8 d-flex flex-column'}>
                            <span>Last Photo: </span>
                            <div>
                                <span className={"color-white badge badge-" + this.state.lastImageHour.badgeColor}>{this.state.lastImageHour.label}h ago</span>
                            </div>
                        </div>
                        <div className={this.state.lastImageB64 === "error" ? 'hidden' : 'col-4 d-flex justify-content-center align-items-center flex-column'}>
                            <span>+{this.state.inputImages.length - 1}</span>
                            <span>Photos</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

} 
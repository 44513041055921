/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import savingLoading from "./../../../../../../assets/saving-loading.gif";

import "./tab-report.scss";
import { generateReport, sendReport } from './../../../services/traps.service';

import moment from "moment";

export default class TabReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //**** Start - Inputs ****//
            inputTrapID: this.props.trapID,
            inputPhones: this.props.phones,
            //**** End - Inputs ****//

            //**** Start - Variables ****//
            reportDate: moment().format("yyyy-MM-DD"),
            reportAmountFlies: 0,
            generatingReport: false,
            errorReq: "",
            successReq: "",
            //**** End - Variables ****//
        };
    }


    async componentDidMount() { }

    generatePDF = () => {
        this.setState({ generatingReport: true });
        let postBody = {
            "target": this.state.reportAmountFlies,
            "trapID": this.state.inputTrapID,
            "date": moment(this.state.reportDate).format("DD/MM/YYYY"),
        };
        console.log(postBody);

        generateReport(postBody).then(res => {
            console.log(res);
            window.open(URL.createObjectURL(res.data));

            this.setState({ successReq: "Generated successfully" });
            setTimeout(() => {
                this.setState({ successReq: "" });
                this.setState({ generatingReport: false });
            }, 2000);
        }).catch(err => {
            this.setState({ errorReq: err.message === "Request failed with status code 500" ? "Please, check the entered information and try again" : err.message });
            setTimeout(() => {
                this.setState({ errorReq: "" });
                this.setState({ generatingReport: false });
            }, 2000);
        });
    }


    sendPDF = () => {
        this.setState({ generatingReport: true });
        let postBody = {
            "target": this.state.reportAmountFlies,
            "trapID": this.state.inputTrapID,
            "date": moment(this.state.reportDate).format("DD/MM/YYYY"),
        };

        generateReport(postBody).then(async (res) => {
            let b64PDF = await this.getBase64(res.data);

            let sendReportBody = {
                "to": this.state.inputPhones,
                "description": "TRD",
                "message": "Mensagem de teste 123",
                "pdf": b64PDF,
                "type": "whatsapp",
            };
            sendReport(sendReportBody).then(resSend => {
                // window.open(URL.createObjectURL(res.data));
                // console.log(res);

                this.setState({ successReq: "Sent successfully" });
                setTimeout(() => {
                    this.setState({ successReq: "" });
                    this.setState({ generatingReport: false });
                }, 2000);
            }).catch(err => {
                this.setState({ errorReq: err.message === "Request failed with status code 500" ? "Please, check the entered information and try again" : err.message });
                setTimeout(() => {
                    this.setState({ errorReq: "" });
                    this.setState({ generatingReport: false });
                }, 2000);
            });
        }).catch(err => {
            this.setState({ errorReq: err.message === "Request failed with status code 500" ? "Please, check the entered information and try again" : err.message });
            setTimeout(() => {
                this.setState({ errorReq: "" });
                this.setState({ generatingReport: false });
            }, 2000);
        });
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
        this.setState({ invalidName: this.name === "" ? true : false });
        this.setState({ invalidDescription: this.description === "" ? true : false });
    }

    toggle = (input) => {
        this.setState({ [input]: !this.state[input] });
    }

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    render() {
        return (
            <div className='row mb-5 pb-5'>
                <div className='col-12 mb-5 pb-5'>
                    <div className='row mt-1 mb-5 pb-5'>
                        <div className="col-md-4 mt-3">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Date</div>
                                </div>
                                <input type="date" className="form-control" title="date" name="reportDate" value={this.state.reportDate} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">How many fruit flies were identified?</div>
                                </div>
                                <input type="number" className="form-control" name="reportAmountFlies" value={this.state.reportAmountFlies} onChange={this.handleChange} />
                            </div>
                        </div>

                        <div className='col-md-2 mt-3'>
                            <Dropdown disabled={this.state.loadingReq || this.state.generatingReport} className={this.state.id !== "w-100" ? '' : 'hidden'} isOpen={this.state.togglePdf} toggle={() => this.toggle("togglePdf")}>

                                <DropdownToggle className='w-100' color="primary">
                                    <div className={this.state.generatingReport ? "hidden" : ""}>
                                        Generate
                                    </div>
                                    <div className={this.state.generatingReport ? "" : "hidden"}>
                                        Generate
                                        <img src={savingLoading} className="ml-2" height="25px" alt="" />
                                    </div>
                                </DropdownToggle>

                                <DropdownMenu>
                                    <DropdownItem onClick={() => this.generatePDF()}>View</DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={() => this.sendPDF()}>Send WhatsApp</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className={this.state.errorReq !== "" || this.state.successReq !== "" ? 'row mt-2 mb-n4' : 'hidden'}>
                        <div className="col d-flex justify-content-end align-items-center">
                            <div className={this.state.errorReq !== "" ? 'alert alert-danger' : 'hidden'} style={{ position: 'relative' }} role="alert">
                                {this.state.errorReq}
                            </div>
                            <div className={this.state.successReq !== "" ? 'alert alert-success' : 'hidden'} style={{ position: 'relative' }} role="alert">
                                {this.state.successReq}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

} 
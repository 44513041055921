import React, { Component } from 'react';
import "./season-manage.scss";
import 'react-web-tabs/dist/react-web-tabs.css';
import { getInfo, create, update, del } from '../services/season.service';
import { getList as cultureGetList } from '../../culture/services/culture.service';
import { getList as farmGetList } from '../../farm/services/farm.service';
import { getList as fieldGetList } from '../../field/services/field.service';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import savingLoading from "./../../../../assets/saving-loading.gif";

export default class SeasonManage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: props.match.params.id,

            loading: true,
            loadingReq: false,
            successReq: null,
            errorReq: null,
            error: null,
            invalidName: false,
            deleteToggle: false,

            // dados criação de culturas
            cultureList: [],
            farmList: [],
            fieldList: [],

            cultureId: -1,
            invalidCulture: false,

            farmId: -1,
            invalidFarm: false,

            fieldId: -1,
            invalidField: false,
        };
    }

    componentDidMount() {
        if (this.state.id === "new") {
            this.setState({ loading: false });
        } else {
            getInfo(this.state.id).then(res => {
                this.setState({
                    ...res.data.data,
                    cultureId: res.data.data.culture,
                    farmId: res.data.data.farm,
                    fieldId: res.data.data.field,
                });
                this.setState({ loading: false });
            }).catch(err => {
                this.setState({ loading: false });
                this.setState({ error: err.message });
            });
        }
        this.loadCultures();
        this.loadFarms();
        this.loadFields();
    }

    toggle = () => this.setState({ deleteToggle: !this.state.deleteToggle });

    delete = () => {
        this.setState({ loadingReq: true });
        this.setState({ successReq: null });
        this.setState({ errorReq: null });

        del(this.state.id).then(res => {
            this.setState({ successReq: "Deleted successfully" });
            setTimeout(() => {
                this.props.history.push('/season');
            }, 1500);
        }).catch(err => {
            this.setState({ loadingReq: false });
            this.setState({ errorReq: err.message });
        });
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            invalidName: this.name === "" ? true : false,
        });
    }

    save = () => {
        this.setState({ loadingReq: true });
        this.setState({ successReq: null });
        this.setState({ errorReq: null });

        let _invalidForm = [];

        if (this.state.name == null || this.state.name === "") _invalidForm.push("name");
        if (this.state.cultureId == null || this.state.cultureId === -1) _invalidForm.push("culture");
        if (this.state.farmId == null || this.state.farmId === -1) _invalidForm.push("farm");
        if (this.state.fieldId == null || this.state.fieldId === -1) _invalidForm.push("field");
        if (this.state.dateTimeStart == null || this.state.dateTimeStart === "") _invalidForm.push("Date Time Start");
        if (this.state.dateTimeEnd == null || this.state.dateTimeEnd === "") _invalidForm.push("Date Time End");

        if (_invalidForm.length > 0) {
            let _message = "Please, check the " + _invalidForm.join(", ") + " form fields and try again!";
            this.setState({ errorReq: _message, loadingReq: false });
            return;
        }

        let _body = {
            name: this.state.name,
            dateTimeStart: this.state.dateTimeStart,
            dateTimeEnd: this.state.dateTimeEnd,
            culture: this.state.cultureId,
            farm: this.state.farmId,
            field: this.state.fieldId,
        };

        if (this.state.id === "new") {
            create(_body).then(res => {
                this.setState({ successReq: "Saved successfully" });
                setTimeout(() => {
                    this.props.history.push('/season');
                }, 1500);
            }).catch(err => {
                this.setState({ loadingReq: false });
                this.setState({ errorReq: err.message === "Request failed with status code 500" ? "Please, check the entered information and try again" : err.message });
            });
        } else {
            update(this.state.id, _body).then(res => {
                this.setState({ successReq: "Saved successfully" });
                setTimeout(() => {
                    this.props.history.push('/season/' + this.state.id);
                }, 1500);
            }).catch(err => {
                this.setState({ loadingReq: false });
                this.setState({ errorReq: err.message === "Request failed with status code 500" ? "Please, check the entered information and try again" : err.message });
            });
        }
    }

    loadCultures = () => {
        cultureGetList().then(res => {
            this.setState({ cultureList: res.data.data });
        }).catch(err => {
            console.log(err)
        })
    }

    loadFarms = () => {
        farmGetList().then(res => {
            this.setState({ farmList: res.data.data });
        }).catch(err => {
            console.log(err)
        })
    }

    loadFields = () => {
        fieldGetList().then(res => {
            this.setState({ fieldList: res.data.data });
        }).catch(err => {
            console.log(err)
        })
    }

    getFieldOptions = () => {
        let _return = [];

        if (this.state.farmId && this.state.farmId !== -1) {
            _return.push(<option key={"selectafield"} value={-1}>Select a Field</option>);
            _return = [..._return, ...this.state.fieldList.map((field) => {
                if (this.state.farmId === field.farmId)
                    return <option value={field._id} key={field._id + field.name}>{field.name}</option>
                return undefined
            }).filter(field => field !== undefined)]
        } else {
            _return.push(<option key={"selectafarmfirst"} value={-1}>Select a Farm First</option>);
        }

        return _return;
    }

    render() {

        return (
            this.state.loading ? <div className="pre-loader"></div> :
                this.state.error != null ? <div className="h-100 w-100 d-flex d-flex justify-content-center align-items-center mt-n5">
                    <div>
                        <h4>Ooops, something is wrong!</h4>
                        <div className="alert alert-danger w-100" role="alert">
                            {this.state.error}
                        </div>
                    </div>
                </div> :
                    <div>
                        <div className="row mt-3">
                            <div className="col d-flex justify-content-between align-items-center">
                                <div>
                                    <h2 className={this.state.id === "new" ? "hidden" : ""}>Season {this.state.name} Manage</h2>
                                    <h2 className={this.state.id === "new" ? "" : "hidden"}>Create Season</h2>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <Dropdown disabled={this.state.loadingReq} className={this.state.id !== "new" ? '' : 'hidden'} isOpen={this.state.deleteToggle} toggle={() => this.toggle()}>
                                        <DropdownToggle color="danger">
                                            Delete
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => this.delete()}>Confirm</DropdownItem>
                                            <DropdownItem divider />
                                            <DropdownItem onClick={() => this.toggle()}>Cancel</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <button className="btn btn-outline-danger ml-5" disabled={this.state.loadingReq} onClick={() => this.props.history.push('/season/' + this.state.id)}>Cancel</button>
                                    <button className="btn btn-success ml-2" disabled={this.state.loadingReq} onClick={() => this.save()}>
                                        <div className={this.state.loadingReq ? "hidden" : ""}>
                                            Save
                                        </div>
                                        <div className={this.state.loadingReq ? "" : "hidden"}>
                                            Saving...
                                            <img src={savingLoading} className="ml-2" height="25px" alt="" />
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className={this.state.errorReq !== null || this.state.successReq !== null ? 'row mt-0 mb-n4' : 'hidden'}>
                            <div className="col d-flex justify-content-end align-items-center">
                                <div className={this.state.errorReq !== null ? 'alert alert-danger' : 'hidden'} style={{ position: 'relative' }} role="alert">
                                    {this.state.errorReq}
                                </div>
                                <div className={this.state.successReq !== null ? 'alert alert-success' : 'hidden'} style={{ position: 'relative' }} role="alert">
                                    {this.state.successReq}
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-3">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Name {this.state.invalidName}</div>
                                    </div>
                                    <input type="text" className={this.state.invalidName ? "form-control is-invalid" : "form-control"} name="name" value={this.state.name} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Season Culture</div>
                                    </div>
                                    <select className={'form-control'} name="cultureId" value={this.state.cultureId} onChange={this.handleChange}>
                                        <option value={-1}>Select a Culture</option>
                                        {this.state.cultureList.map((culture) => {
                                            return <option value={culture._id} key={culture._id + culture.name}>{culture.name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Season Starting Date</div>
                                    </div>
                                    <input type="date" className="form-control" value={this.state.dateTimeStart} name="dateTimeStart" title="Date time start" onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Season Ending Date</div>
                                    </div>
                                    <input type="date" className="form-control" value={this.state.dateTimeEnd} name="dateTimeEnd" title="Date time end" onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className='col-md-3 col-sm-12'>
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Farm</div>
                                    </div>
                                    <select className={'form-control'} name="farmId" value={this.state.farmId} onChange={this.handleChange}>
                                        <option value={-1}>Select a Farm</option>
                                        {this.state.farmList.map((farm) => {
                                            return <option value={farm._id} key={farm._id + farm.name}>{farm.name}</option>
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className='col-md-3 col-sm-12'>
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Field</div>
                                    </div>
                                    <select className={'form-control'} name="fieldId" value={this.state.fieldId} onChange={this.handleChange}>
                                        {this.getFieldOptions()}
                                    </select>
                                </div>
                            </div>

                        </div>

                    </div>
        )
    }
}


/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';

import { getImage } from '../../../services/traps.service';
import "./trap-summary.scss";

import moment from 'moment';
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import L from 'leaflet';

import { getInfo as getInfoField } from "../../../../field/services/field.service";

export default class TrapSummary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //**** Start - Inputs ****//
            inputDescription: this.props.description,
            inputImages: this.props.images,
            inputLat: this.props.lat,
            inputLng: this.props.lng,
            inputFieldID: this.props.fieldID,
            inputlocalIP: this.props.localIP,
            //**** End - Inputs ****//

            //**** Start - Variables ****//
            lastImageB64: "",
            mapZoom: 14,
            lastImageHour: {},
            field: null,
            //**** End - Variables ****//
        };

        console.log(this.state.lastImageB64)
        this.myIcon = new L.Icon({
            iconUrl: require('../../../../../../assets/marker.png'),
            iconSize: new L.Point(45, 45),
            iconAnchor: [22, 44],
        });
    }

    async componentDidMount() {
        this.setState({ lastImageB64: await this.getB64(this.state.inputImages.at(-1)) });
        this.setState({ lastImageHour: this.getDateDiff(this.state.lastImageB64.createdAt) })
        this.loadField();
    }

    async loadField() {
        getInfoField(this.state.inputFieldID).then(res => {
            if (res.data.code === "SUCCESS") {
                this.setState({ field: res.data.data });
            } else {
                this.setState({ field: "error" });
            }
        }).catch(e => {
            this.setState({ field: "error" });
        });
    }

    async getB64(id) {
        var _return;
        await getImage(id).then(res => {
            _return = res.data.data;
        }).catch(err => {
            _return = "error";
            console.log("Erro ao carregar b64");
        });
        return _return;
    }

    getDateDiff(date) {
        var hours = moment.duration(moment().diff(moment(date))).asHours();

        if (hours < 5) return { label: hours.toFixed(0), badgeColor: "success" };
        // else if (hours < 11) return { label: hours.toFixed(0), badgeColor: "danger" };
        else return { label: hours.toFixed(0) < 999 ? hours.toFixed(0) : "+999", badgeColor: "danger" };
    }

    render() {
        const marker = (
            <Marker position={[this.state.inputLat, this.state.inputLng]} icon={this.myIcon}>
                <Popup>You soil are here</Popup>
            </Marker>
        )

        return (
            <div className='mt-3 mx-2'>
                <div className='row py-3 shadow rounded'>
                    <div className='col-md-9 d-flex flex-column align-items-between'>

                        <div className='row'>
                            <div className='col d-flex flex-row justify-content-center'>
                                <b className='mr-1'>Total Photos: </b>
                                <span>{this.state.inputImages.length}</span>
                            </div>
                            <div className='col d-flex flex-row justify-content-center'>
                                <b>Last Photo: </b>
                                <div className={this.state.lastImageB64 === "error" ? "hidden" : "ml-2"}>
                                    <span className={"mr-1 color-white badge badge-" + this.state.lastImageHour.badgeColor}>{this.state.lastImageHour.label}h ago</span>
                                    <span>({moment(this.state.lastImageB64.createdAt).format('DD/MM/YYYY - HH:mm')})</span>
                                </div>
                            </div>
                            <div className='col d-flex flex-row justify-content-center'>
                                <b className='mr-1'>Field Name: </b>
                                {this.state.field === null ? "Loading..." : this.state.field === "error" ? "No available" : this.state.field.name}
                            </div>
                            <div className={sessionStorage.getItem('role') === "admin" ? 'col d-flex flex-row justify-content-center' : 'hidden'}>
                                <b className='mr-1'>Local IP: </b>
                                <span className={this.state.inputlocalIP != null ? '' : 'hidden'}>
                                    <a href={'http://' + this.state.inputlocalIP}>{this.state.inputlocalIP}</a>
                                </span>
                                <span className={this.state.inputlocalIP == null ? '' : 'hidden'}>
                                    Don't have local IP
                                </span>
                            </div>
                        </div>

                        <div className='row ml-1 mt-3 border-top'>
                            <div className='col-md-12 pl-0 mt-2 mb-2'>
                                <b>Description: </b>
                                <span>{this.state.inputDescription}</span>
                            </div>
                        </div>

                    </div>
                    <div className='col-md-3'>
                        <Map
                            center={[this.state.inputLat, this.state.inputLng]}
                            length={4}
                            zoom={this.state.mapZoom}
                            style={{ height: "165px" }}
                            className="rounded"
                        >
                            <TileLayer
                                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicmFmYWVsLWxhdXhlbiIsImEiOiJjanlvbThnbmoxNjM2M2JudHhqc3ZtZXdqIn0.v3sa933wVSor5tM_zSkLww"
                            />
                            {marker}
                        </Map>
                    </div>
                </div>
            </div>
        );
    }

} 
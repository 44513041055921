import React, { Component } from 'react';
import { getInfo } from '../services/traps.service';
import "./traps-info.scss";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import { Link } from 'react-router-dom';

import TrapSummary from './components/trap-summary/trap-summary';
import TrapNavTabs from './components/trap-nav-tabs/trap-nav-tabs';

export default class TrapsInfo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // variaveis de controle
            id: props.match.params.id,
            loading: true,
            error: null,
        };

    }

    componentDidMount() {
        if (this.state.id === "new") {
            this.setState({ loading: false });
        } else {
            getInfo(this.state.id).then(res => {
                this.setState({ ...res.data.data });
                this.setState({ loading: false });
            }).catch(err => {
                this.setState({ loading: false });
                this.setState({ error: err.message });
            });
        }
    }

    getPhones = () => {
        return this.state.fieldId.farmId.phoneNotifications.map((phone) => (
            "55" + phone.telephone.replace(/[`~!@#$%^&*() _|+\-=?;:'",.<>{}[\]\\/]/gi, '')
        ));
    }

    render() {
        return (
            this.state.loading ? <div className="pre-loader"></div> :
                this.state.error != null ? <div className="h-100 w-100 d-flex justify-content-center align-items-center mt-5">
                    <div>
                        <h4>Ooops, something is wrong!</h4>
                        <div className="alert alert-danger w-100" role="alert">
                            {this.state.error}
                        </div>
                    </div>
                </div> :
                    <div className='container-fluid'>
                        <div className="row mt-3">
                            <div className='col-sm-6 col'>
                                <h2>Trap {this.state.name}</h2>
                            </div>
                            <div className={sessionStorage.getItem('role') === "admin" ? 'col-sm-6 col d-flex justify-content-end align-items-center' : 'hidden'}>
                                <button className="btn btn-outline-primary ml-5" onClick={() => this.props.history.push('/traps')}>Back</button>
                                <button className="btn btn-success ml-2">
                                    <Link className='hide-link' to={"/traps/manage/" + this.state.id}>
                                        Manage
                                    </Link>
                                </button>
                            </div>
                            <div className={sessionStorage.getItem('role') !== "admin" ? 'col-sm-6 col d-flex justify-content-end align-items-center' : 'hidden'}>
                                <button className="btn btn-outline-primary ml-5" onClick={() => this.props.history.push('/traps')}>Back</button>
                            </div>
                            {/* </div> */}
                        </div>

                        <div className='row'>
                            <div className='col'>
                                <TrapSummary
                                    description={this.state.description}
                                    images={this.state.images}
                                    lat={this.state.map.lat}
                                    lng={this.state.map.lng}
                                    fieldID={this.state.fieldId._id}
                                    localIP={this.state.localIP}
                                ></TrapSummary>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <TrapNavTabs
                                    trapID={this.state.id}
                                    imagesIDs={this.state.images}
                                    phones={this.getPhones()}
                                    report={this.state.reports}
                                ></TrapNavTabs>
                            </div>
                        </div>
                    </div>
        )
    }
}
import React, { Component } from 'react';
import "./culture-info.scss";
import 'react-web-tabs/dist/react-web-tabs.css';
import moment from 'moment';
import { getInfo, create, update, del } from '../services/culture.service';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import savingLoading from "./../../../../assets/saving-loading.gif";

class Cultureinfo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            typeOfCrop: "AnnualCropsAndDeciduousTressShrubs",
            selectCrop: 'NonGrowingIsPriorToPlatingInitiation',
            selectgroundcover: 'Usedefault',
            selectinput: 'inputfc',
            selectcropdensity: 'inputkcb',
            selectinputmode: 'InputKcbFullAtStandardCropDevelopmentStages',
            selectkcb: 'adjustfraction',
            selectfc: 'adjustfcestimated',
            selectyield: 'UseUniqueKey',
            developmentStages: {
                nonGrowing: {
                    date: moment().format("YYYY-MM-DD"),
                    rootDepth: 0,
                    plantHeight: 0,
                    pFraction: 0
                },
                plantingStart: {
                    date: moment().format("YYYY-MM-DD"),
                    rootDepth: 0,
                    plantHeight: 0,
                    pFraction: 0
                },
                rapidGrowth: {
                    date: moment().format("YYYY-MM-DD"),
                    rootDepth: 0,
                    plantHeight: 0,
                    pFraction: 0
                },
                halfSeason: {
                    date: moment().format("YYYY-MM-DD"),
                    rootDepth: 0,
                    plantHeight: 0,
                    pFraction: 0
                },
                senescence: {
                    date: moment().format("YYYY-MM-DD"),
                    rootDepth: 0,
                    plantHeight: 0,
                    pFraction: 0
                },
                harvest: {
                    date: moment().format("YYYY-MM-DD"),
                    rootDepth: 0,
                    plantHeight: 0,
                    pFraction: 0
                },
            },
            entryFormInput: "fc",

            fcStartOffSeason: 0,
            fcPlanting: 0,
            fcStartRapidGrowth: 0,
            fcStartRapidSeason: 0,
            fcStartSenescence: 0,
            fcEndSeason: 0,
            inputFcTable: [
                { date: moment().format("YYYY-MM-DD"), fc: 0 }
            ],

            inputBasalTable: [
                { date: moment().format("YYYY-MM-DD"), fc: 0 }
            ],
            basalOffSeason: 0,
            basalInitialPeriod: 0,
            basalMidSeason: 0,
            basalEndSeason: 0,
            basalAdjustSpecifiedKcb: false,

            inputYieldTable: [
                { name: "", startDate: moment().format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD"), ky: 0 }
            ],
            yieldIHaveData: 0,
            yieldEnterKyValue: 0,

            currentPage: 0,
            basalChartOptions: {
                title: {
                    text: ''
                },
                xAxis: {
                    categories: ["|", "Initial", "|", "Rapid growth", "|", "Mid Season", "|", "Late season", "|"],
                },
                tooltip: {
                    formatter: function () {
                        return this.y.toFixed(2);
                    }
                },
                yAxis: {
                    title: {
                        text: null
                    }
                },
                series: [{
                    showInLegend: false,
                    marker: {
                        enabled: false
                    },
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
                }]
            },
            id: props.match.params.id,
            loadingReq: false,
            successReq: null,
            errorReq: null,
            error: null,
            invalidName: false,
            deleteToggle: false,
        };
    }

    componentDidMount() {

        if (this.state.id === "new") {
            this.setState({ loading: false });
        } else {
            getInfo(this.state.id).then(res => {
                this.setState({ ...res.data.data });
                this.setState({ loading: false });
                setTimeout(() => {
                    this.basalChartOptionsUpdate();
                }, 1000);
            }).catch(err => {
                this.setState({ loading: false });
                this.setState({ error: err.message });
            });
        }

    }

    toggle = () => this.setState({ deleteToggle: !this.state.deleteToggle });

    delete = () => {
        this.setState({ loadingReq: true });
        this.setState({ successReq: null });
        this.setState({ errorReq: null });

        del(this.state.id).then(res => {
            this.setState({ successReq: "Deleted successfully" });
            setTimeout(() => {
                this.props.history.push('/culture');
            }, 1500);
        }).catch(err => {
            this.setState({ loadingReq: false });
            this.setState({ errorReq: err.message });
        });
    }

    handleChange = async (event) => {
        var _name = event.target.name;
        var _value = event.target.value;
        await this.setState({ [_name]: _value });

        if (_name === "basalInitialPeriod" || _name === "basalMidSeason" || _name === "basalEndSeason") {
            this.basalChartOptionsUpdate();
        }

        setTimeout(() => {
            this.setState({ invalidName: this.state.name === "" ? true : false });
        }, 100);
    }

    handleChangeDevelopmentStages = (event) => {
        var _developmentStages = this.state.developmentStages;
        _developmentStages[event.target.name][event.target.title] = event.target.value;
        this.setState({ developmentStages: _developmentStages });
    }

    inputFcTableAdd = () => {
        this.setState({ inputFcTable: [...this.state.inputFcTable, { date: moment().format("YYYY-MM-DD"), fc: 0 }] });
    }

    inputFcTableRemove = (index) => {
        if (this.state.inputFcTable.length > 1) {
            var array = [...this.state.inputFcTable];
            array.splice(index, 1);
            this.setState({ inputFcTable: array });
        }
    }

    inputFcTableHandleChange = (event, index) => {
        var _inputFcTable = this.state.inputFcTable;
        _inputFcTable[index][event.target.name] = event.target.value;
        this.setState({ inputFcTable: _inputFcTable });
    }

    inputBasalTableAdd = () => {
        this.setState({ inputBasalTable: [...this.state.inputBasalTable, { date: moment().format("YYYY-MM-DD"), fc: 0 }] });
    }

    inputBasalTableRemove = (index) => {
        if (this.state.inputBasalTable.length > 1) {
            var array = [...this.state.inputBasalTable];
            array.splice(index, 1);
            this.setState({ inputBasalTable: array });
        }
    }

    inputBasalTableHandleChange = (event, index) => {
        var _inputBasalTable = this.state.inputBasalTable;
        _inputBasalTable[index][event.target.name] = event.target.value;
        this.setState({ inputBasalTable: _inputBasalTable });
    }

    inputYieldTableAdd = () => {
        this.setState({ inputYieldTable: [...this.state.inputYieldTable, { name: "", startDate: moment().format("YYYY-MM-DD"), endDate: moment().format("YYYY-MM-DD"), ky: 0 }] });
    }

    inputYieldTableRemove = (index) => {
        if (this.state.inputYieldTable.length > 1) {
            var array = [...this.state.inputYieldTable];
            array.splice(index, 1);
            this.setState({ inputYieldTable: array });
        }
    }

    inputYieldTableHandleChange = (event, index) => {
        var _inputYieldTable = this.state.inputYieldTable;
        _inputYieldTable[index][event.target.name] = event.target.value;
        this.setState({ inputYieldTable: _inputYieldTable });
    }

    basalChartOptionsUpdate = () => {
        var _basalChartOptions = {
            title: {
                text: ''
            },
            xAxis: {
                categories: ["|", "Initial", "|", "Rapid growth", "|", "Mid Season", "|", "Late season", "|"],
            },
            tooltip: {
                formatter: function () {
                    return this.y.toFixed(2);
                }
            },
            yAxis: {
                title: {
                    text: null
                }
            },
            series: [{
                showInLegend: false,
                marker: {
                    enabled: false
                },
                data: [parseFloat(this.state.basalInitialPeriod), parseFloat(this.state.basalInitialPeriod), parseFloat(this.state.basalInitialPeriod), (parseFloat(this.state.basalInitialPeriod) + parseFloat(this.state.basalMidSeason)) / 2, parseFloat(this.state.basalMidSeason), parseFloat(this.state.basalMidSeason), parseFloat(this.state.basalMidSeason), (parseFloat(this.state.basalMidSeason) + parseFloat(this.state.basalEndSeason)) / 2, parseFloat(this.state.basalEndSeason)],
            }]
        };

        this.setState({ basalChartOptions: _basalChartOptions });
    }

    save = () => {
        this.setState({ loadingReq: true });
        this.setState({ successReq: null });
        this.setState({ errorReq: null });

        if (this.state.id === "new") {
            create(this.state).then(res => {
                this.setState({ successReq: "Saved successfully" });
                setTimeout(() => {
                    this.props.history.push('/culture');
                }, 1500);
            }).catch(err => {
                this.setState({ loadingReq: false });
                this.setState({ invalidName: this.state.name === "" ? true : false });
                this.setState({ errorReq: err.message === "Request failed with status code 500" ? "Please, check the entered information and try again" : err.message });
            });
        } else {
            update(this.state.id, this.state).then(res => {
                this.setState({ successReq: "Saved successfully" });
                setTimeout(() => {
                    this.props.history.push('/culture');
                }, 1500);
            }).catch(err => {
                this.setState({ loadingReq: false });
                this.setState({ invalidName: this.state.name === "" ? true : false });
                this.setState({ errorReq: err.message === "Request failed with status code 500" ? "Please, check the entered information and try again" : err.message });
            });
        }
    }

    toggleChange = () => {
        this.setState({
            basalAdjustSpecifiedKcb: !this.state.basalAdjustSpecifiedKcb,
        });
    }

    render() {

        return (
            this.state.loading ? <div className="pre-loader"></div> :
                this.state.error != null ? <div className="h-100 w-100 d-flex d-flex justify-content-center align-items-center mt-n5">
                    <div>
                        <h4>Ooops, something is wrong!</h4>
                        <div className="alert alert-danger w-100" role="alert">
                            {this.state.error}
                        </div>
                    </div>
                </div> :
                    <div>
                        <div className="row mt-3">
                            <div className="col d-flex justify-content-between align-items-center">
                                <div>
                                    <h2 className={this.state.id === "new" ? "hidden" : ""}>{this.state.name} Culture Information</h2>
                                    <h2 className={this.state.id === "new" ? "" : "hidden"}>Create Culture</h2>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <Dropdown disabled={this.state.loadingReq} className={this.state.id !== "new" ? '' : 'hidden'} isOpen={this.state.deleteToggle} toggle={() => this.toggle()}>
                                        <DropdownToggle color="danger">
                                            Delete
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => this.delete()}>Confirm</DropdownItem>
                                            <DropdownItem divider />
                                            <DropdownItem onClick={() => this.toggle()}>Cancel</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <button className="btn btn-outline-danger ml-5" disabled={this.state.loadingReq} onClick={() => this.props.history.push('/culture')}>Cancel</button>
                                    <button className="btn btn-success ml-2" disabled={this.state.loadingReq} onClick={() => this.save()}>
                                        <div className={this.state.loadingReq ? "hidden" : ""}>
                                            Save
                                        </div>
                                        <div className={this.state.loadingReq ? "" : "hidden"}>
                                            Saving...
                                            <img src={savingLoading} className="ml-2" height="25px" alt="" />
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className={this.state.errorReq !== null || this.state.successReq !== null ? 'row mt-0 mb-n4' : 'hidden'}>
                            <div className="col d-flex justify-content-end align-items-center">
                                <div className={this.state.errorReq !== null ? 'alert alert-danger' : 'hidden'} style={{ position: 'relative' }} role="alert">
                                    {this.state.errorReq}
                                </div>
                                <div className={this.state.successReq !== null ? 'alert alert-success' : 'hidden'} style={{ position: 'relative' }} role="alert">
                                    {this.state.successReq}
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Culture Name</div>
                                    </div>
                                    <input type="text" className={this.state.invalidName ? "form-control is-invalid" : "form-control"} name="name" value={this.state.name} onChange={this.handleChange}></input>
                                </div>
                            </div>
                        </div>
                    </div >
        )
    }
}

export default Cultureinfo;

import { authAxios } from "./../../../../interceptors/auth";

// const base_url = "http://localhost:3001/"; //local
const base_url = "https://marine-outpost-327402.uc.r.appspot.com/"; //deploy 

export function getList() {
    return authAxios.get(base_url + "farms");
}

export function getFarm() {
    return authAxios.get(base_url + "farms");
}

export function getInfo(_id) {
    return authAxios.get(base_url + "farms/" + _id);
}

export function create(_farm) {
    return authAxios.post(base_url + "farms", _farm);
}

export function update(_id, _farm) {
    console.log(_farm);
    return authAxios.post(base_url + "farms/" + _id, _farm);
}

export function del(_id) {
    return authAxios.delete(base_url + "farms/" + _id);
} 

export function getListWithFields() {
    return authAxios.get(base_url + "farms");
} 
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';

import "./tab-identification.scss";

import moment from "moment";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export default class TabIdentification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //**** Start - Inputs ****//
            inputReport: this.props.report,
            //**** End - Inputs ****//

            //**** Start - Variables ****//
            options: null,
            errorReq: "",
            successReq: "",
            //**** End - Variables ****//
        };
    }


    async componentDidMount() {
        let _options = {
            chart: {
                height: "300px"
            },
            title: {
                text: 'Fruit Fly Identification'
            },
            xAxis: {
                type: "datetime",
                labels: {
                    formatter: function () {
                        return Highcharts.dateFormat('%e %b', this.value);
                    }
                }
            },
            yAxis: {
                title: {
                    text: "Identification"
                }
            },
            tooltip: {
                useHTML: true,
                headerFormat: "<b>{point.key}</b></br>",
                xDateFormat: '%B %d'
            },
            series: [],
        };

        _options.series.push({
            name: "Identification",
            color: "#427ef5",
            data: this.state.inputReport.map(e => (
                {
                    x: moment(e.date, "DD/MM/YYYY").valueOf(),
                    y: e.target,
                }
            ))
        });

        this.setState({ options: _options });
    }

    render() {
        return (
            <div className='row'>
                <div className={this.state.inputReport.length === 0 ? "col-12 d-flex justify-content-center" : "hidden"}>
                    <h6 className='my-5 py-5'> There are no identification, try come back later!</h6>
                </div>
                <div className={this.state.inputReport.length !== 0 ? "col-12 pt-5 pb-2" : "hidden"}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={this.state.options}
                    />
                </div>
            </div>
        );
    }

} 
import axios from "axios";
import { authAxios } from "./../../../../interceptors/auth";

// const base_url = "http://localhost:3001/"; //local
const base_url = "https://marine-outpost-327402.uc.r.appspot.com/"; //deploy 

export function getList() {
    return authAxios.get(base_url + "traps");
}

export function getInfo(_id) {
    return authAxios.get(base_url + "traps/" + _id);
}

export function getImage(_id) {
    return authAxios.get(base_url + "traps/image/" + _id);
}

export function create(_season) {
    return authAxios.post(base_url + "traps", _season);
}

export function update(_id, _season) {
    return authAxios.post(base_url + "traps/" + _id, _season);
}

export function del(_id) {
    return authAxios.delete(base_url + "traps/" + _id);
}

export function getListWithFields() {
    return axios.get(base_url + "traps");
}

export function generateReport(_data) {
    return authAxios.post(base_url + "reports/62a72e741f6b7f733943e234/generate",
        _data, { responseType: 'blob' });
}

export function sendReport(_data) {
    return authAxios.post(base_url + "senders", _data);
} 
import React, { Component } from 'react';
import "./farm-info.scss";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import L from 'leaflet';
import "leaflet-draw/dist/leaflet.draw.css"
import savingLoading from "./../../../../assets/saving-loading.gif"
import { getInfo, create, update, del } from '../services/farm.service';
import { getList } from '../../field/services/field.service';
import InputMask from 'react-input-mask';
class Farminfo extends Component {

    constructor(props) {
        super(props);

        this.state = {

            //valores que pertencem aos componentes
            deleteToggle: false,
            id: props.match.params.id,
            loading: true,
            error: null,
            invalidName: false,
            loadingReq: false,
            errorReq: null,
            successReq: null,

            //dados da fazenda
            name: "",
            address: "",
            area: "",

            //mapa
            map: {
                lat: -13,
                lng: -52,
                zoom: 3,
                hasLocation: false,
                markerPos: { lat: 0, lng: 0 },
            },

            avaibleFields: [],
            fieldId: "",

            phoneNotifications: [
                { name: "", telephone: ""}
            ],



        };

        //marcador
        this.myIcon = new L.Icon({
            //Url do Marcador
            iconUrl: require('../../../../assets/marker.png'),
            iconSize: new L.Point(45, 45),
            iconAnchor: [22, 44],
        });
    }


    componentDidMount() {
        if (this.state.id === "new") {
            this.setState({ loading: false });

        } else {
            getInfo(this.state.id).then(res => {
                this.setState({ ...res.data.data });
                this.setState({ loading: false });
                if (this.state.map.markerPos.lat !== null) {
                    var _map = { ...this.state.map };
                    _map.lat = _map.markerPos.lat.toFixed(4);
                    _map.lng = _map.markerPos.lng.toFixed(4);
                    _map.zoom = 8;
                    this.setState({ map: _map });
                }
            }).catch(err => {
                console.log(err);
                this.setState({ loading: false });
                this.setState({ error: err.message });
            });
        }
        this.getFields()
    }

    componentDidUpdate = (prevProps, prevState) => {

        if (this.state.shapeFile !== prevState.shapeFile) {
            var allow = Object.keys(this.state.shapeFile).length === 0;

            this.setState({
                allowDraw: allow,
            })
        }
    }

    handleChange = (event) => { this.setState({ [event.target.name]: event.target.value }); this.setState({ invalidName: this.name === "" ? true : false }); }

    handleChangeLatLng = (event) => {
        var _map = { ...this.state.map };
        _map.markerPos[event.target.name] = event.target.value;
        _map.hasLocation = true;
        this.setState({ map: _map });
        this.setState({ invalidLng: this.state.map.markerPos.lng === "" ? true : false });
        this.setState({ invalidLat: this.state.map.markerPos.lat === "" ? true : false });
    }

    handleClick = (e) => {
        var _map = { ...this.state.map };
        _map.markerPos = e.latlng;
        _map.hasLocation = true;
        _map.lat = _map.markerPos.lat.toFixed(4);
        _map.lng = _map.markerPos.lng.toFixed(4);
        _map.markerPos.lat = _map.markerPos.lat.toFixed(4);
        _map.markerPos.lng = _map.markerPos.lng.toFixed(4);
        this.setState({ map: _map });
    }

    //Deletar o solo
    delete = () => {
        this.setState({ loadingReq: true });
        this.setState({ errorReq: null });

        del(this.state.id).then(res => {
            this.setState({ successReq: "Deleted successfully" });
            setTimeout(() => {
                this.props.history.push('/farm');
            }, 1500);
        }).catch(err => {
            this.setState({ loadingReq: false });
            this.setState({ errorReq: err.message });
        });
    }
    //Salvar o solo
    save = () => {
        this.setState({ loadingReq: true });
        this.setState({ successReq: null });

        if (this.state.id === "new") {
            create(this.state).then(res => {
                this.setState({ successReq: "Saved successfully" });
                setTimeout(() => {
                    this.props.history.push('/farm');
                }, 1500);
            }).catch(err => {
                console.log(err);
                this.setState({ loadingReq: false });
                this.setState({ invalidName: this.state.name === "" ? true : false });
                this.setState({ invalidLng: this.state.map.markerPos.lng === "" ? true : false });
                this.setState({ invalidLat: this.state.map.markerPos.lat === "" ? true : false });
                this.setState({ errorReq: err.message === "Request failed with status code 500" ? "Please, check the entered information and try again" : err.message });
            });
        } else {
            update(this.state.id, this.state).then(res => {
                this.setState({ successReq: "Saved successfully" });
                setTimeout(() => {
                    this.props.history.push('/farm');
                }, 1500);
            }).catch(err => {
                this.setState({ loadingReq: false });
                this.setState({ invalidName: this.state.name === "" ? true : false });
                console.log()
                this.setState({ errorReq: err.message === "Request failed with status code 500" ? "Please, check the entered information and try again" : err.message });
            });
        }
    }

    //deletar
    toggle = () => this.setState({ deleteToggle: !this.state.deleteToggle });

    //mapa
    handleClickMap = (e) => {
        var _map = { ...this.state.map };
        _map.markerPos = e.latlng;
        _map.hasLocation = true;
        this.setState({ map: _map });
    }

    //field
    getFields = () => {
        getList().then(res => {
            var arrived = res.data.data
            this.setState({
                avaibleFields: arrived
            })
        }).catch(err => {
            console.log(err)
        })

    }

    buildFields = () => {
        var fieldsArr = this.state.avaibleFields

        var options = fieldsArr.map((field) => {
            return <option value={field._id} key={field._id + field.name}>{field.name}</option>
        })


        return (
            <select className={'form-control'} name="fieldId" value={this.state.fieldId} onChange={this.handleChange}>
                <option value={-1}>Select a Field</option>
                {options}
            </select>
        )

    }

    phoneNotificationsAdd = () => {
        this.setState({ phoneNotifications: [...this.state.phoneNotifications, { name: "", telephone: "" }] });
    }

    phoneNotificationsRemove = (index) => {
        if (this.state.phoneNotifications.length > 1) {
            var array = [...this.state.phoneNotifications];
            array.splice(index, 1);
            this.setState({ phoneNotifications: array });
        }
    }

    phoneNotificationsHandleChange = (event, index) => {
        var phoneNotifications = this.state.phoneNotifications;
        phoneNotifications[index][event.target.name] = event.target.value;
        this.setState({ phoneNotifications: phoneNotifications });
    }

    render() {
        //Marcador da localizacao no mapa
        const marker = this.state.map.hasLocation ? (
            <Marker position={[this.state.map.markerPos.lat, this.state.map.markerPos.lng]} icon={this.myIcon}>
                <Popup>You soil are here</Popup>
            </Marker>
        ) : null


        return (
            //Carregamento da pagina
            this.state.loading ? <div className="pre-loader"></div> :
                this.state.error != null ? <div className="h-100 w-100 d-flex d-flex justify-content-center align-items-center mt-n5">
                    <div>
                        {/*Mensagem de erro*/}
                        <h4>Ooops, something's  wrong!</h4>
                        <div className="alert alert-danger w-100" role="alert">
                            {this.state.error}
                        </div>
                    </div>
                </div> :
                    <div className="pb-4">

                        <div className="row mt-3">
                            <div className="col d-flex justify-content-between align-items-center">
                                <div>
                                    {/*Nome do solo*/}
                                    <h2 className={this.state.id === "new" ? "hidden" : ""}>{this.state.name} Information</h2>
                                    <h2 className={this.state.id === "new" ? "" : "hidden"}>Create Farm</h2>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <Dropdown disabled={this.state.loadingReq} className={this.state.id !== "new" ? '' : 'hidden'} isOpen={this.state.deleteToggle} toggle={() => this.toggle()}>
                                        {/*Deletar*/}
                                        <DropdownToggle color="danger">
                                            Delete
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {/*Confirmar*/}
                                            <DropdownItem onClick={() => this.delete()}>Confirm</DropdownItem>
                                            <DropdownItem divider />
                                            {/*Cancelar*/}
                                            <DropdownItem onClick={() => this.toggle()}>Cancel</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <button className="btn btn-outline-danger ml-5" disabled={this.state.loadingReq} onClick={() => this.props.history.push('/farm')}>Cancel</button>
                                    <button className="btn btn-success ml-2" disabled={this.state.loadingReq} onClick={() => this.save()}>
                                        {/*Carregando para salvar*/}
                                        <div className={this.state.loadingReq ? "hidden" : ""}>
                                            Save
                                        </div>
                                        <div className={this.state.loadingReq ? "" : "hidden"}>
                                            Saving...
                                            <img src={savingLoading} className="ml-2" height="25px" alt="" />
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className={this.state.errorReq !== null || this.state.successReq !== null ? 'row mt-0 mb-n5' : 'hidden'}>
                            <div className="col d-flex justify-content-end align-items-center">
                                <div className={this.state.errorReq !== null ? 'alert alert-danger' : 'hidden'} style={{ position: 'relative' }} role="alert">
                                    {this.state.errorReq}
                                </div>
                                <div className={this.state.successReq !== null ? 'alert alert-success' : 'hidden'} style={{ position: 'relative' }} role="alert">
                                    {this.state.successReq}
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-6">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        {/*Nome do Solo*/}
                                        <div className="input-group-text">Farm Name {this.state.invalidName}</div>
                                    </div>
                                    <input type="text" className={this.state.invalidName ? "form-control is-invalid" : "form-control"} name="name" value={this.state.name} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Farm Address</div>
                                    </div>
                                    <input type="text" className="form-control" name="address" value={this.state.address} onChange={this.handleChange} />
                                </div>
                            </div>

                        </div>

                        <div className="row mt-3">
                            <div className='col-md-6'>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Area</div>
                                    </div>
                                    <input type="number" className="form-control" name="area" value={this.state.area} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        {/*Latitude*/}
                                        <div className="input-group-text">Latitude</div>
                                    </div>
                                    {/*Salva os dados da Latitude no input*/}
                                    <input type="number" className="form-control" name="lat" value={this.state.map.markerPos.lat} onChange={this.handleChangeLatLng} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Longitude</div>
                                    </div>
                                    <input type="number" className="form-control" name="lng" value={this.state.map.markerPos.lng} onChange={this.handleChangeLatLng} />
                                </div>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className='col-md-6'>
                                <h4 className='d-flex justify-content-start'>Phone Notifications</h4>
                                {this.state.phoneNotifications.map((element, index) =>
                                    <div key={index} className="row mt-3">
                                        <div className="ml-4 d-flex justify-content-start">
                                            <button className="btn btn-outline-danger btn-remove pb-2 mt-1" onClick={() => this.phoneNotificationsRemove(index)}>—</button>
                                        </div>
                                        <div className="col">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <input type="text" className="form-control" name="name" placeholder="Description" value={this.state.phoneNotifications[index].name}
                                                        onChange={event => this.phoneNotificationsHandleChange(event, index)} />
                                                </div>
                                                <div className="col-md-6">
                                                    <InputMask mask="+99 9999-9999" maskChar={null} type="text" className="form-control" name="telephone" placeholder="Telephone" value={this.state.phoneNotifications[index].telephone}
                                                        onChange={event => this.phoneNotificationsHandleChange(event, index)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <button className="btn btn-outline-success btn-sm w-100" onClick={() => this.phoneNotificationsAdd()}> + </button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className='row'>
                                    <div className='col'>
                                        {/* MAPA? */}
                                        <Map
                                            center={[this.state.map.lat, this.state.map.lng]}
                                            length={4}
                                            onClick={this.handleClickMap}
                                            zoom={this.state.map.zoom}
                                            style={{ height: "430px", borderRadius: "10px" }}
                                            ref={(ref) => { this.mapRef = ref; }}
                                            onZoomEnd={this.setMapZoom}
                                            whenReady={this.onMapReady}>
                                            <TileLayer
                                                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                url="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicmFmYWVsLWxhdXhlbiIsImEiOiJjanlvbThnbmoxNjM2M2JudHhqc3ZtZXdqIn0.v3sa933wVSor5tM_zSkLww"
                                            />
                                            
                                            {marker}
                                        </Map>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
        );
    }

}

export default Farminfo;

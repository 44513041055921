/* eslint-disable jsx-a11y/alt-text */
import moment from 'moment';
import React, { Component } from 'react';

import "./season-summary.scss";

import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import L from 'leaflet';

export default class SeasonSummary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //**** Start - Inputs ****//
            inputDateTimeStart: this.props.dateTimeStart,
            inputDateTimeEnd: this.props.dateTimeEnd,
            inputFarmName: this.props.farmName,
            inputFieldName: this.props.fieldName,
            inputMarkerLat: this.props.lat,
            inputMarkerLng: this.props.lng,
            //**** End - Inputs ****//

            //**** Start - Variables ****//
            mapZoom: 16,
            dtStartFormated: "Loading...",
            dtEndFormated: "Loading...",
            //**** End - Variables ****//
        };

        this.myIcon = new L.Icon({
            iconUrl: require('../../../../../../assets/marker.png'),
            iconSize: new L.Point(45, 45),
            iconAnchor: [22, 44],
        });
    }

    async componentDidMount() {
        this.setState({
            dtStartFormated: moment(this.state.inputDateTimeStart).format("DD/MM/YYYY"),
            dtEndFormated: moment(this.state.inputDateTimeEnd).format("DD/MM/YYYY"),
            durationInDays: moment(this.state.inputDateTimeEnd).diff(moment(this.state.inputDateTimeStart), 'days'),
        });
    }

    render() {
        const marker = (
            <Marker position={[this.state.inputMarkerLat, this.state.inputMarkerLng]} icon={this.myIcon}>
                <Popup>You soil are here</Popup>
            </Marker>
        )

        return (
            <div className='row py-3 shadow rounded'>
                <div className='col-md-9 d-flex flex-column align-items-between'>
                    <div className='row'>
                        <div className='col-md-3 my-1'>
                            <b>Season Period: </b>
                            <span>{this.state.dtStartFormated} at {this.state.dtEndFormated}</span>
                        </div>
                        <div className='col-md-3 my-1'>
                            <b>Duration: </b>
                            <span>{this.state.durationInDays} days</span>
                        </div>
                        <div className='col-md-3 my-1'>
                            <b>Farm Name: </b>
                            <span>{this.state.inputFarmName}</span>
                        </div>
                        <div className='col-md-3 my-1'>
                            <b>Field Name: </b>
                            <span>{this.state.inputFieldName}</span>
                        </div>
                    </div>
                </div>
                <div className='col-md-3'>
                    <Map
                        center={[this.state.inputMarkerLat, this.state.inputMarkerLng]}
                        length={4}
                        zoom={this.state.mapZoom}
                        style={{ height: "165px" }}
                        className="rounded"
                    >
                        <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicmFmYWVsLWxhdXhlbiIsImEiOiJjanlvbThnbmoxNjM2M2JudHhqc3ZtZXdqIn0.v3sa933wVSor5tM_zSkLww"
                        />
                        {marker}
                    </Map>
                </div>
            </div>
        );
    }
} 
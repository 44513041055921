import React from 'react';
import './styles.scss';

class App extends React.Component {
  render() {
    return (
      <div className="d-flex justify-content-center align-items-center dashboard-main">
        <div>
          <h3 className="d-flex justify-content-center align-items-center">Welcome to Tech Trap</h3>
          <div className="d-flex justify-content-center align-items-center">
          You are in Home Page
          </div>
        </div>

      </div>
    );
  }
}
export default App;
import { authAxios } from "./../../../../interceptors/auth";

// const base_url = "http://localhost:3001/"; //local
const base_url = "https://marine-outpost-327402.uc.r.appspot.com/"; //deploy 

export function getList() {
    return authAxios.get(base_url + "seasons");
}

export function getInfo(_id) {
    return authAxios.get(base_url + "seasons/" + _id);
}

export function getPopulated(_id) {
    return authAxios.get(base_url + "seasons/populated/" + _id);
}

export function create(_season) {
    console.log(_season)
    return authAxios.post(base_url + "seasons", _season);
}

export function update(_id, _season) {
    return authAxios.post(base_url + "seasons/" + _id, _season);
}

export function del(_id) {
    return authAxios.delete(base_url + "seasons/" + _id);
}

export function getListWithCultures() {
    return authAxios.get(base_url + "seasons");
} 

/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';

import "./tab-photos-list.scss";

import moment from 'moment';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import InnerImageZoom from 'react-inner-image-zoom';
import preLoader from "./../../../../../../assets/pre-loader.gif";

import { getImage } from '../../../services/traps.service';

import Pagination from '@mui/material/Pagination';

export default class TabPhotosList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            //**** Start - Inputs ****//
            inputPhotosIDs: this.props.photosIDs,
            //**** End - Inputs ****//

            //**** Start - Variables ****//
            b64: [],
            pagesCount: 1,
            pageSize: 12,
            currentPage: 0,
            modal: false,
            //**** End - Variables ****//
        };
        this.openModal = this.openModal.bind(this);
    }

    //modal
    openModal(element) {
        // console.log(element) //recebe o id da foto clicada
        this.setState({ aux: element });

        this.setState({
            modal: !this.state.modal,
        });
    }

    async componentDidMount() {
        this.state.inputPhotosIDs.forEach(async (element, index) => {
            var aux = this.state.b64;
            aux[index] = "loading";
            this.setState({ b64: aux });
        });

        this.loadRangePhotos(0); // Load first page

        this.setState({ pagesCount: Math.ceil(this.state.inputPhotosIDs.length / this.state.pageSize) });
    }

    async loadRangePhotos(pageIndex) {
        let iStart = pageIndex * this.state.pageSize
        let iEnd = iStart + this.state.pageSize;

        this.reverse(this.state.inputPhotosIDs).forEach(async (element, index) => {
            if (index >= iStart && index < iEnd) {
                var newImage = await this.getB64(element);
                var aux = this.state.b64;
                aux[index] = newImage;
                this.setState({ b64: aux });
            }
        });
    }

    reverse(array) {
        return array.map((item, index) => array[array.length - 1 - index])
    }

    //chamar imagem no servidor e armazenar no this.state.images[id]
    async getB64(id) {
        var _return;
        await getImage(id).then(res => {
            _return = res.data.data;
        }).catch(err => {
            console.log("Erro ao carregar b64");
        });
        return _return;
    }

    changePage(e, index) {

        e.preventDefault();

        index--;

        this.setState({
            currentPage: index
        });

        this.loadRangePhotos(index);
    }

    render() {
        return (
            <div className='row'>
                <div className='col-12'>
                    <div className={this.state.inputPhotosIDs.length === 0 ? "nophotos d-flex justify-content-center align-items-center" : "hidden"}>
                        <div>
                            <h4>No photos yet..</h4>
                        </div>
                    </div>
                    <div className={this.state.inputPhotosIDs.length !== 0 ? "" : "hidden"}>
                        <div className="row pb-2">
                            {this.state.b64.slice(
                                this.state.currentPage * this.state.pageSize,
                                (this.state.currentPage + 1) * this.state.pageSize
                            ).map((element, id) =>
                                <div className='col-sm-12 col-md-2' key={id}>
                                    <div className='modalho traps-item d-flex justify-content-center shadow-sm'>
                                        <img className='img-fluid thumbnail mt-2' src={typeof (element) === "string" ? preLoader : `data:image/jpeg;base64,${element.b64}`} alt={element} onClick={() => this.openModal(element.b64)} />
                                    </div>
                                    <div className='d-flex justify-content-center mt-3'>
                                        <h6>{moment(element.createdAt).format('DD/MM/YYYY - HH:mm')}</h6>
                                    </div>
                                </div>
                            )}
                        </div>

                        <Modal isOpen={this.state.modal} toggle={this.openModal} className={this.props.className} style={{ maxWidth: '1600px', width: '54%' }}>
                            <ModalHeader toggle={this.openModal}></ModalHeader>
                            <ModalBody>
                                <InnerImageZoom src={`data:image/jpeg;base64,${this.state.aux}`} alt="" />
                            </ModalBody>
                        </Modal>

                        <div className="d-flex align-items-stretch justify-content-center">
                            <Pagination count={this.state.pagesCount} onChange={(e, page) => this.changePage(e, page)} color="primary" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

} 
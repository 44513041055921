import React, { Component } from 'react';
import "./season-info.scss";
import { getPopulated } from '../services/season.service';
import { Link } from 'react-router-dom';

import SeasonSummary from "./components/season-summary/season-summary";

export default class Seasoninfo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: props.match.params.id,
            activeTab: "trap",
            loading: true,
        };
    }

    componentDidMount() {
        getPopulated(this.state.id).then(res => {
            this.setState({ ...res.data.data });
            this.setState({ loading: false });
        }).catch(err => {
            this.setState({ loading: false });
            this.setState({ error: err.message });
        });
    }

    setActiveTab(id) {
        this.setState({ activeTab: id });
    }

    render() {

        return (
            this.state.loading ? <div className="pre-loader"></div> :
                this.state.error != null ? <div className="h-100 w-100 d-flex d-flex justify-content-center align-items-center mt-n5">
                    <div>
                        <h4>Ooops, something is wrong!</h4>
                        <div className="alert alert-danger w-100" role="alert">
                            {this.state.error}
                        </div>
                    </div>
                </div> :
                    <div className='container-fluid'>
                        <div className="row mt-3">
                            <div className="col d-flex justify-content-between align-items-center">
                                <h2>Season {this.state.name} Information</h2>
                                <div className="d-flex justify-content-between align-items-center">
                                    <button className="btn btn-outline-primary ml-5" disabled={this.state.loadingReq} onClick={() => this.props.history.push('/season')}>Back</button>
                                    <button className="btn btn-success ml-2" disabled={this.state.loadingReq}  >
                                        <Link className='hide-link' to={"/season/manage/" + this.state.id}>
                                            Manage
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-2'>
                            <div className='col'>
                                <SeasonSummary
                                    dateTimeStart={this.state.dateTimeStart}
                                    dateTimeEnd={this.state.dateTimeEnd}
                                    farmName={this.state.farm.name}
                                    fieldName={this.state.field.name}
                                    lat={this.state.farm.map.lat}
                                    lng={this.state.farm.map.lng}
                                ></SeasonSummary>
                            </div>
                        </div>
                        {/* <div className='row shadow rounded mt-3 mb-3 py-3'>
                            <div className='col-12'>
                                <div className='d-flex justify-content-center align-items-end'>
                                    <button
                                        className={this.state.activeTab === "trap" ? "btn btn-sm btn-primary mx-1 shadow-sm" : "btn btn-sm btn-outline-primary mx-1 shadow-sm"}
                                        onClick={e => this.setActiveTab('trap')}>
                                        Traps
                                    </button>
                                </div>
                                <div className="outlet border-top mt-3">
                                    {this.state.activeTab === "trap" ? <span>Trap list</span> : null}
                                </div>
                            </div>
                        </div> */}
                    </div>
        )
    }
} 

import React, { Component } from 'react';
import "./traps-list.scss";
import { Link } from 'react-router-dom'; 
import { getList } from '../services/traps.service';
import Pagination from '@mui/material/Pagination';

import LastImage from './components/last-image/last-image';

class Traps extends Component {

    constructor() {

        super();

        this.state = {
            currentPage: 0,
            dataSet: [],
            loading: true,
            error: null,
            search: "",
            dataBackup: [],
            orderby: "name",
        };

        this.pageSize = 12;
        this.pagesCount = Math.ceil(this.state.dataSet.length / this.pageSize);
    }

    componentDidMount() {

        getList().then(res => {
            this.setState({ loading: false });
            this.setState({ dataSet: res.data.data });
            this.setState({ dataBackup: res.data.data });
            // this.state.dataSet = res.data.data;
            this.pageSize = 12;
            this.pagesCount = Math.ceil(this.state.dataSet.length / this.pageSize);
            this.handleChangeOrder({ target: { value: this.state.orderby } });
            this.setState({ currentPage: 0 });
        }).catch(err => {
            this.setState({ loading: false });
            this.setState({ error: err.message });
        });
    }

    handleClick(e, index) {

        e.preventDefault();

        index--;

        this.setState({
            currentPage: index
        });

    }

    search = async (text) => {
        var textSearch = text.target.value;
        this.setState({ search: textSearch });
        await this.setState({ dataSet: [...this.state.dataBackup] });
        if (textSearch !== "") {
            var filteredData = this.state.dataSet.filter(data => data.name.toLowerCase().includes(textSearch.toLowerCase()));
            this.setState({ dataSet: filteredData });
            this.handleChangeOrder({ target: { value: this.state.orderby } });
        }
    }

    handleChangeOrder = async (e) => {
        var _order = e.target.value;
        this.setState({ orderby: _order });
        var sortedData = [];
        if (_order === "createdAt") {
            sortedData = this.state.dataSet.sort((a, b) => (a[_order] < b[_order]) ? 1 : ((b[_order] < a[_order]) ? -1 : 0));
        } else {
            sortedData = this.state.dataSet.sort((a, b) => (a[_order] > b[_order]) ? 1 : ((b[_order] > a[_order]) ? -1 : 0));
        }
        this.setState({ dataSet: sortedData });
    }

    render() {

        return (
            this.state.loading ? <div className="pre-loader"></div> :
                this.state.error != null ? <div className="h-100 w-100 d-flex d-flex justify-content-center align-items-center mt-n5">
                    <div>
                        <h4>Ooops, something is wrong!</h4>
                        <div className="alert alert-danger w-100" role="alert">
                            {this.state.error}
                        </div>
                    </div>
                </div> :

                    <div className="d-flex flex-column mt-2">

                        <div className="row d-flex justify-content-between">
                            <div className="col-md-3 mt-2">
                                <div className={sessionStorage.getItem('role') === "admin" ? '' : 'hidden'}>
                                    <Link style={{ color: '#FFF' }} to="/traps/manage/new">
                                        <button className="btn btn-primary">
                                            Add traps
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3 offset-md-4">
                                <div className="input-group mt-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Search</div>
                                    </div>
                                    <input type="text" className="form-control" placeholder="Type anything to search..." value={this.state.search} onChange={this.search} />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="input-group mt-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Order By</div>
                                    </div>
                                    <select className="custom-select" value={this.state.orderby} onChange={this.handleChangeOrder}>
                                        <option value="name">Name</option>
                                        <option value="createdAt">Last Created</option>
                                    </select>
                                </div>
                            </div>
                        </div>


                        <div className={this.state.dataBackup.length === 0 && this.state.loading === false ? "w-100 d-flex d-flex justify-content-center mt-5" : "hidden"}>

                            <div className={sessionStorage.getItem('role') === "admin" ? '' : 'hidden'}>
                                <h4>Please, try begin creating a new traps</h4>
                                <Link style={{ color: '#FFF' }} to="/traps/manage/new">
                                    <button className="btn btn-primary w-100 mt-4">
                                        Add traps
                                    </button>
                                </Link>
                            </div>

                            <div className={sessionStorage.getItem('role') !== "admin" ? '' : 'hidden'}>
                                <h4>No data found, please contact your provider for more details...</h4>
                            </div>

                        </div>


                        <div className={this.state.dataSet.length === 0 && this.state.loading === false ? "h-100 w-100 d-flex d-flex justify-content-center align-items-center mt-5" : "hidden"}>
                            <div>
                                <h4>Sorry, not founds results for your search.</h4>
                            </div>
                        </div>

                        <React.Fragment>

                            <div className="row mt-1">
                                {this.state.dataSet
                                    .slice(
                                        this.state.currentPage * this.pageSize,
                                        (this.state.currentPage + 1) * this.pageSize
                                    )
                                    .map((value) =>
                                        <div key={value._id} className="col-md-2 col-sm-12">
                                            <Link to={"/traps/" + value._id}>
                                                <div className="card shadow w-100 mt-4 traps-item">
                                                    <div className="card-body cardcolor">
                                                        <h5 className="d-flex justify-content-center mb-3"><p>{value.name.replace(/([A-Z])/g, ' $1').trim()}</p></h5>
                                                        {/* <hr></hr> */}
                                                        {/* <p className='card-description-h'>{value.description.replace(/([A-Z])/g, ' $1').trim()}</p> */}
                                                        <LastImage images={value.images}></LastImage>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )}
                            </div>

                            <div className={this.state.dataSet.length !== 0 && this.state.loading === false ? "d-flex align-items-end justify-content-center pagination" : "hidden"}>
                                <Pagination count={this.state.pagesCount} onChange={(e, page) => this.handleClick(e, page)} color="primary" />
                            </div>

                        </React.Fragment>

                    </div>
        );
    }
}

export default Traps;
import React, { Component } from 'react';
//import moment from 'moment';
import { getInfo, create, update, del } from '../services/field.service';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import savingLoading from "./../../../../assets/saving-loading.gif";
import { getList } from '../../culture/services/culture.service';
import { getList as farmList } from '../../farm/services/farm.service';
import L from 'leaflet';
import { Map, TileLayer, Marker, Popup } from "react-leaflet";



class Irrigationinfo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            invalidName: false,
            name: "",
            area: 0,
            fruit: '',
            // variaveis de controle
            id: props.match.params.id,
            loadingReq: false,
            successReq: null,
            errorReq: null,
            error: null,
            deleteToggle: false,

            avaibleCultures: [],
            cultureId: "",

            avaibleFarms: [],
            farmId: "",

            //map
            map: {
                lat: -13,
                lng: -52,
                zoom: 3,
                hasLocation: false,
                markerPos: { lat: 0, lng: 0 },
            },
        };
        //marcador
        this.myIcon = new L.Icon({
            //Url do Marcador
            iconUrl: require('../../../../assets/marker.png'),
            iconSize: new L.Point(45, 45),
            iconAnchor: [22, 44],
        });

    }

    componentDidMount() {

        if (this.state.id === "new") {
            this.setState({ loading: false });
        } else {
            getInfo(this.state.id).then(res => {
                this.setState({ ...res.data.data });
                this.setState({ loading: false });
            }).catch(err => {
                this.setState({ loading: false });
                this.setState({ error: err.message });
            });

            if (this.state.map.markerPos.lat !== null) {
                var _map = { ...this.state.map };
                _map.lat = _map.markerPos.lat.toFixed(4);
                _map.lng = _map.markerPos.lng.toFixed(4);
                _map.zoom = 8;
                this.setState({ map: _map });
            }
        }
        this.getCultures()
        this.getFarms()

    }

    toggleChange = (event) => {
        this.setState({
            [event.target.name]: !this.state[event.target.name],
        });
    }

    save = () => {
        this.setState({ loadingReq: true });
        this.setState({ successReq: null });
        this.setState({ errorReq: null });

        if (this.state.id === "new") {
            create(this.state).then(res => {
                this.setState({ successReq: "Saved successfully" });
                setTimeout(() => {
                    this.props.history.push('/field');
                }, 1500);
            }).catch(err => {
                this.setState({ loadingReq: false });
                this.setState({ invalidName: this.state.irrigationName === "" ? true : false });
                this.setState({ invalidLng: this.state.map.markerPos.lng === "" ? true : false });
                this.setState({ invalidLat: this.state.map.markerPos.lat === "" ? true : false });
                this.setState({ errorReq: err.message === "Request failed with status code 500" ? "Please, check the entered information and try again" : err.message });
            });
        } else {
            update(this.state.id, this.state).then(res => {
                this.setState({ successReq: "Saved successfully" });
                setTimeout(() => {
                    this.props.history.push('/field');
                }, 1500);
            }).catch(err => {
                this.setState({ loadingReq: false });
                this.setState({ invalidName: this.state.irrigationName === "" ? true : false });
                this.setState({ errorReq: err.message === "Request failed with status code 500" ? "Please, check the entered information and try again" : err.message });
            });
        }
    }

    toggle = () => this.setState({ deleteToggle: !this.state.deleteToggle });

    delete = () => {
        this.setState({ loadingReq: true });
        this.setState({ successReq: null });
        this.setState({ errorReq: null });

        del(this.state.id).then(res => {
            this.setState({ successReq: "Deleted successfully" });
            setTimeout(() => {
                this.props.history.push('/field');
            }, 1500);
        }).catch(err => {
            this.setState({ loadingReq: false });
            this.setState({ errorReq: err.message });
        });
    }

    handleChange = (event) => { this.setState({ [event.target.name]: event.target.value }); this.setState({ invalidName: this.name === "" ? true : false }); }

    handleChangeLatLng = (event) => {
        var _map = { ...this.state.map };
        _map.markerPos[event.target.name] = event.target.value;
        _map.hasLocation = true;
        this.setState({ map: _map });
        this.setState({ invalidLng: this.state.map.markerPos.lng === "" ? true : false });
        this.setState({ invalidLat: this.state.map.markerPos.lat === "" ? true : false });
    }

    handleClick = (e) => {
        var _map = { ...this.state.map };
        _map.markerPos = e.latlng;
        _map.hasLocation = true;
        _map.lat = _map.markerPos.lat.toFixed(4);
        _map.lng = _map.markerPos.lng.toFixed(4);
        _map.markerPos.lat = _map.markerPos.lat.toFixed(4);
        _map.markerPos.lng = _map.markerPos.lng.toFixed(4);
        this.setState({ map: _map });
    }

    handleClickMap = (e) => {
        var _map = { ...this.state.map };
        _map.markerPos = e.latlng;
        _map.hasLocation = true;
        this.setState({ map: _map });
    }

    getCultures = () => {
        getList().then(res => {
            var arrived = res.data.data
            this.setState({
                avaibleCultures: arrived
            })
        }).catch(err => {
            console.log(err)
        })

    }

    buildCultures = () => {
        var culturesArr = this.state.avaibleCultures

        var options = culturesArr.map((culture) => {
            return <option value={culture._id} key={culture._id + culture.name}>
                {culture.name}
            </option>
        })


        return (
            <select className={'form-control'} name="cultureId" value={this.state.cultureId} onChange={this.handleChange}>
                <option value={-1}>Select a Culture</option>
                {options}
            </select>
        )

    }

    getFarms = () => {
        farmList().then(res => {
            var arrived = res.data.data
            this.setState({
                avaibleFarms: arrived
            })
        }).catch(err => {
            console.log(err)
        })

    }

    buildFarms = () => {
        var farmsArr = this.state.avaibleFarms

        var options = farmsArr.map((farm) => {
            return <option value={farm._id} key={farm._id + farm.name}>
                {farm.name}
            </option>
        })


        return (
            <select className={'form-control'} name="farmId" value={this.state.farmId} onChange={this.handleChange}>
                <option value={-1}>Select a Farm</option>
                {options}
            </select>
        )

    }
    render() {
        //Marcador da localizacao no mapa
        const marker = this.state.map.hasLocation ? (
            <Marker position={[this.state.map.markerPos.lat, this.state.map.markerPos.lng]} icon={this.myIcon}>
                <Popup>You soil are here</Popup>
            </Marker>
        ) : null
        return (
            this.state.loading ? <div className="pre-loader"></div> :
                this.state.error != null ? <div className="h-100 w-100 d-flex d-flex justify-content-center align-items-center mt-n5">
                    <div>
                        <h4>Ooops, something is wrong!</h4>
                        <div className="alert alert-danger w-100" role="alert">
                            {this.state.error}
                        </div>
                    </div>
                </div> :
                    <div>
                        <div className="row mt-3">
                            <div className="col d-flex justify-content-between align-items-center">
                                <div>
                                    <h2 className={this.state.id === "new" ? "hidden" : ""}>{this.state.name} Information</h2>

                                    <h2 className={this.state.id === "new" ? "" : "hidden"}>Create Field</h2>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <Dropdown disabled={this.state.loadingReq} className={this.state.id !== "new" ? '' : 'hidden'} isOpen={this.state.deleteToggle} toggle={() => this.toggle()}>
                                        <DropdownToggle color="danger">
                                            Delete
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => this.delete()}>Confirm</DropdownItem>
                                            <DropdownItem divider />
                                            <DropdownItem onClick={() => this.toggle()}>Cancel</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <button className="btn btn-outline-danger ml-5" disabled={this.state.loadingReq} onClick={() => this.props.history.push('/field')}>Cancel</button>
                                    <button className="btn btn-success ml-2" disabled={this.state.loadingReq} onClick={() => this.save()}>
                                        <div className={this.state.loadingReq ? "hidden" : ""}>
                                            Save
                                        </div>
                                        <div className={this.state.loadingReq ? "" : "hidden"}>
                                            Saving...
                                            <img src={savingLoading} className="ml-2" height="25px" alt="" />
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-md-3">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Name {this.state.invalidName}</div>
                                    </div>
                                    <input type="text" className={this.state.invalidName ? "form-control is-invalid" : "form-control"} name="name" value={this.state.name} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Area</div>
                                    </div>
                                    <input type="number" className="form-control" name="area" value={this.state.area} onChange={this.handleChange} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Culture</div>
                                    </div>
                                    {this.buildCultures()}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Farms</div>
                                    </div>
                                    {this.buildFarms()}
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className="col-md-3">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        {/*Latitude*/}
                                        <div className="input-group-text">Latitude</div>
                                    </div>
                                    {/*Salva os dados da Latitude no input*/}
                                    <input type="number" className="form-control" name="lat" value={this.state.map.markerPos.lat} onChange={this.handleChangeLatLng} />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">Longitude</div>
                                    </div>
                                    <input type="number" className="form-control" name="lng" value={this.state.map.markerPos.lng} onChange={this.handleChangeLatLng} />
                                </div>
                            </div>
                        </div>

                        <div className='row mb-2 mt-3'>
                            <div className='col'>
                                {/* MAPA? */}
                                <Map
                                    center={[this.state.map.lat, this.state.map.lng]}
                                    length={4}
                                    onClick={this.handleClickMap}
                                    zoom={this.state.map.zoom}
                                    style={{ height: "430px", borderRadius: "10px" }}
                                    ref={(ref) => { this.mapRef = ref; }}
                                    onZoomEnd={this.setMapZoom}
                                    whenReady={this.onMapReady}>
                                    <TileLayer
                                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicmFmYWVsLWxhdXhlbiIsImEiOiJjanlvbThnbmoxNjM2M2JudHhqc3ZtZXdqIn0.v3sa933wVSor5tM_zSkLww"
                                    />
                                    {marker}
                                </Map>
                            </div>
                        </div>
                    </div>
        )
    }
}

export default Irrigationinfo;